import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/PipelineColDefs";
import DeactivaleColumnDef from "../../../tables/colDefs/DeactivatePromoPipelineColDef";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ChildMessageRenderer from "./childMessageRenderer.js";
import moment from "moment";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import NumericEditor from "./NumericEditor";
import DecimalNumericEditor from "./DecimalNumericEditor";
import "../Promo Calendar/promoCalendarStyle.css";
import Toggle from "react-toggle";
import Modal from "react-awesome-modal";
import {
  getTYPipelineData,
  getCopyCampaignDetails,
  userComments,
  savePipelineCellsMethod,
  pipelineIntermediateApi,
  pipelineGetTyEvents,
  getDeactivatedPromos 
} from "../../../../redux/actions/calendarAction";
import {getSignShellOptions} from '../../../../redux/actions/signShellAction'
import {
  getAdgroupOffer
} from "../../../../redux/actions/SimulationAction";
import {
  getCouponList,
  deleteCoupons,
} from "../../../../redux/actions/couponAction";
import { usersLogReport } from "../../../../redux/actions/userAction";
import _ from "lodash";
import DeleteAdgroup from "../Adgroup/DeleteAdgroup";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CouponPipeline from "../Coupons/CouponPipeline";
import DeleteCouponConfirmationModal from "../Coupons/DeleteCouponConfirmationModal";
import CommentModal from "./OverrideCommentModalPrompt";
import AdgroupChoiceModal from "./AdgroupChoiceModal";
import AddOffersFromLYModal from "./AddOffersFromLYModal";
import UserComments from "./UserCommentsModal";
import PipelineFootnote from "./PipelineFootnote";
import PipelineActionButtons from "./PipelineActionButtons";
import PageLoader from "../../../../utils/Loader";
import PipelineCellRenderer from "./PipelineCellRenderer";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import BulkModifyOffersModal from "./BulkModifyOffersModal";
import CopyOfferModal from "./CopyOfferModal";
import { getBrowserTimeZone } from "../../../../utils/valueFormatters";
import { alertTimer } from "../../../../utils/alertTimer.js";

let selectedPromo = "";
let campaignStartDateGlobal = "";
let campaignEndDateGlobal = "";
var selectedAdgroupCampaignId = "";
var promoId,
  salesForecast,
  unitForecast,
  marginForecast,
  gmForecast,
  original_update_flag;

let pipelineRowClass,
  headerCheck = false,
  cellStyleMethod = null,
  cellStyleMethodNew = null;

function adgroupNameRendrer(params) {
  if (
    params.data.promo_id !== null &&
    params.data.zpes_feed_indicator === "sku_list_mismatch"
  ) {
    return (
      <>
        {params.value}
        <i
          className="fa fa-exclamation actionIcons adgroup-sku-mismatch"
          title="SKU list mismatch from ZPES"
        ></i>
      </>
    );
  }
  return params.value;
}
export class PromotionPipeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pipelineTableExport: false,
      selectedMerchCatArrayState: null,
      adGroupItemsState: [],
      frameworkComponents: {
        childMessageRenderer: ChildMessageRenderer,
        numericEditor: NumericEditor,
        decimalNumericEditor: DecimalNumericEditor,
        pipelineCellRenderer: PipelineCellRenderer,
        adgroupNameRendrer: adgroupNameRendrer,
      },
      context: { componentParent: this },
      disableCopyBtn: true,
      rowSelection: "multiple",
      selectedAdgroupId: [],
      selectedCampaignId: null,
      adgroupDataArray: null,
      promoIdArray: [],
      getRowStyle: function (params) {
        let color_code = params.data.color_code;
        if (
          params.data.campaign_type !== "completed_dm" &&
          params.data.campaign_type !== "ongoing_dm" &&
          // params.data.original_update_flag === 0 && //check added from BE
          params.data.rank === "1" &&
          color_code &&
          color_code.length
        ) {
          return { background: `#${color_code}` };
        }
        return null;
      },
      // rowClassRules: {
      //   "update-flag-data": function (params) {
      //     if (
      //       params.data.original_update_flag === 0 &&
      //       params.data.update_flag === 1
      //     ) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   },
      //   "update-flag-new-adgrpoup ": function (params) {
      //     if (
      //       params.data.original_update_flag === 0 &&
      //       params.data.update_flag === 4
      //     ) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   },
      // },
      showModelOpen: false,
      loaderAg: false,
      editableAdgroups: [],
      deletedCouponAlert: false,
      rowSavedAlert: false,
      deleteModelOpen: false,
      deletePromoData: null,
      simulateTabKey: "pipeline",
      couponTableData: [],
      showSaveCommentModal: false,
      couponLoading: false,
      showConfirmationModal: false,
      couponRowDetails: [],
      toggleLYSelected: false,
      pipelineDataAdGroup: { data: [] },
      diffDays: [],
      tableData: [],
      tableDataLY: {
        header: [],
          defaultColDef: [],
          data: [],
          campaign_id: null
      },
      tableDataTY: {
        header: [], 
          defaultColDef: [],
          data: [],
          campaign_id: null
      },
      showAdgroupChoiceModal: false,
      showModifyChoiceModal: false,
      showAddOffersFromLYModal: false,
      showEditOffersFromLYModal: false,
      selectedView: "OnlyTY",
      tyEventsData: [],
      showLYLoader: false,
      unitRequiredAlert: false,
      threeFieldsAlert: false,
      showAdgroupDeleted: false,
      unitCantBeZero: false,
      commentPromoId: [
        {
          promoId: "",
          adgroup_name: "",
          offer_name: "",
        },
      ],
      skuChangeLogsTableData: [],
      rowRefresh: false,
      rowIndex: "",
      showZpesCol: false,
      showBulkModifyModal: false,
      deactiveTableData: {},
      showCopyOfferModal: false
    };
  }

  async componentDidMount() {
    let startDate = localStorage.getItem("startDate"),
      historyCampId = localStorage.getItem("campaignId"),
      historyCustomer = localStorage.getItem("customerGrp"),
      historyBusiness = localStorage.getItem("businessDesc"),
      historyShop = localStorage.getItem("shop"),
      historyCampStartD = localStorage.getItem("campStartDate"),
      historyCampEndD = localStorage.getItem("campEndDate");

    let custArry =
      historyCustomer && historyCustomer !== "null"
        ? historyCustomer.split(",")
        : [],
      businessArry =
        historyBusiness && historyBusiness !== "null"
          ? historyBusiness.split(",")
          : [],
      shopArry =
        historyShop && historyShop !== "null" ? historyShop.split(",") : [];

    if (!startDate || startDate === "null") {
      return;
    }
    if (historyCampId && historyCampId !== "null") {
      ///// DO NOT remove async await, it'll cause a major backToCalendar issue////
      let a = await this.pipelineTYDataHandler(
        historyCampId,
        historyCampStartD,
        historyCampEndD,
        shopArry,
        businessArry,
        custArry
      );

      localStorage.setItem("startDate", "null");
      localStorage.setItem("endDate", "null");
    }
  }

  pipelineTYDataHandler = async (
    historyCampId,
    historyCampStartD,
    historyCampEndD,
    shopArry,
    businessArry,
    custArry,
    adGrpArr
  ) => {
    this.props.sendFiltersLoading(true, "pipe");

    let campSD = new Date(historyCampStartD),
      campED = new Date(historyCampEndD),
      diffTime = Math.abs(campSD.getTime() - campED.getTime()) + 1,
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.setState({
      campaignStartDate: moment(historyCampStartD).format("MM-DD-YYYY"),
      campaignEndDate: moment(historyCampEndD).format("MM-DD-YYYY"),
      diffDays,
      promotionName: moment(historyCampStartD).format("MM/DD/YYYY"),
    });
    
    localStorage.setItem("promo_shop",JSON.stringify(shopArry))
    localStorage.setItem("promo_business",JSON.stringify(businessArry))
    localStorage.setItem("promo_customer_group",JSON.stringify(custArry))
    localStorage.setItem("promo_adgroups",JSON.stringify(adGrpArr ? adGrpArr : []))
    localStorage.setItem("promo_cstart_date",moment(historyCampStartD).format("YYYY-MM-DD"))
    localStorage.setItem("promo_cend_date",moment(historyCampEndD).format("YYYY-MM-DD"))

    const reqType = this.state.toggleLYSelected ? "All LY" : "All TY"
    let response = await this.props.getTYPipelineData({
      campaign_id: [historyCampId],
      campaign_start_date: moment(historyCampStartD).format("YYYY-MM-DD"),
      campaign_end_date: moment(historyCampEndD).format("YYYY-MM-DD"),
      adgroups: adGrpArr ? adGrpArr : [],
      status: [],
      h3: shopArry,
      h5: businessArry,
      h6: custArry,
      type: reqType
    });
    this.props.sendFiltersLoading(false, "pipe");
    if (!response.success) {
      return;
    }

    let findCampaignType = _.find(this.props.calendarDataPerYear, {
      id: parseInt(historyCampId),
    });
    if (
      response.data &&
      response.data.ty_year_data &&
      response.data.ty_year_data.length
    ) {
      let cloned = _.cloneDeep(response.data.ty_year_data);
      this.setState({
        getTYPipelineData: response.data.ty_year_data,
      });

      let campaignStartD = new Date(historyCampStartD);
      pipelineRowClass = moment(campaignStartD).isSameOrAfter(moment(), 'day')
        ? ""
        : "pipelineCheckboxDisabled";
      headerCheck = moment(campaignStartD).isSameOrAfter(moment(), 'day');

      cellStyleMethod =
        campaignStartD > new Date(moment().format("MM-DD-YYYY"))
          ? {
            color: "#2d69eb",
            cursor: "pointer",
          }
          : {
            color: "black",
            textDecoration: "none",
          };

      cellStyleMethodNew = {
        pointerEvents: "auto",
        cursor: "pointer",
      };

      let tyHeaderCalc = ColumnDef.zpesTYHeader;
      // findCampaignType && findCampaignType.campaign_type === "zpes"
      //     ? ColumnDef.zpesTYHeader
      //     : ColumnDef.TYHeader;

      let pipelineTYHeader = tyHeaderCalc.map((item) => {
        if (item.field === "adgroup_name") {

          return {
            ...item,
            headerCheckboxSelection: headerCheck,
            // cellStyle: cellStyleMethod,
          };
        }
        // if (
        //   item.field === "adjusted_baseline_quantity" ||
        //   item.field === "adjusted_promo_inc_quantity" ||
        //   item.field === "adjusted_other_units" ||
        //   item.field === "adjusted_total_quantity" ||
        //   item.field === "adjusted_baseline_revenue" ||
        //   item.field === "adjusted_promo_inc_revenue" ||
        //   item.field === "adjusted_other_revenue" ||
        //   item.field === "adjusted_total_revenue" ||
        //   item.field === "adjusted_baseline_margin" ||
        //   item.field === "adjusted_promo_inc_margin" ||
        //   item.field === "adjusted_other_margin" ||
        //   item.field === "adjusted_total_margin" ||
        //   item.field === "total_actual_units" ||
        //   item.field === "total_adjusted_units" ||
        //   item.field === "total_actual_sales" ||
        //   item.field === "total_adjusted_sales" ||
        //   item.field === "total_actual_margin" ||
        //   item.field === "total_adjusted_margin" ||
        //   item.field === "actual_gm" ||
        //   item.field === "adjusted_gm"
        // ) {
        //   return {
        //     ...item,
        //     hide: headerCheck,
        //   };
        // }
        if (item.field === "action") {
          return {
            ...item,
            cellStyle: cellStyleMethodNew,
          };
        }
        return item;
      });
      this.setState({
        cloned: cloned,
        pipelineTYHeader: pipelineTYHeader,
        findCampaignType: findCampaignType && findCampaignType.campaign_type
      });
      let responseData = _.map(cloned, (item) => {
        item.getfun2 = this.setUserComments;
        item.getfun3 = this.showModal;
        item.getfun4 = this.editPipelineCells;
        item.prev_units_override = item.total_override_units;
        item.prev_sales_override = item.total_override_sales;
        item.prev_margin_override = item.total_override_margin;
        item.prev_gm_override = item.gm_override;
        item.rowRefreshHandler = this.rowRefreshHandler;
        item.campaign_type = findCampaignType && findCampaignType.campaign_type;
        return item;
      });
      this.setState({
        tableDataTY: {
          header: pipelineTYHeader,
          defaultColDef: ColumnDef.defaultColDef,
          data: responseData,
          campaign_id: historyCampId
        },
        zpesColStatus:
          findCampaignType && findCampaignType.campaign_type !== "future_dm"
            ? true
            : false,
        showZpesCol: true,
        setColStatus: headerCheck,
      });
      let campStrtD = cloned[0].campaign_start_date;
      let campEndD = cloned[0].campaign_end_date;
      if (
        moment(historyCampStartD).format("MM-DD-YYYY") === "Invalid date" ||
        moment(campStrtD).format("MM-DD-YYYY") !==
        moment(historyCampStartD).format("MM-DD-YYYY")
      ) {
        /*
        this.setState({
          campaignStartDate: moment(campStrtD).format("MM-DD-YYYY"),
          campaignEndDate: moment(campEndD).format("MM-DD-YYYY"),
          promotionName: moment(campStrtD).format("MM/DD/YYYY"),
        });
        */
      }
    } else {
      if(reqType === 'All TY'){
        this.setState({
          tableDataTY: {
            header: ColumnDef.TYHeader,
            defaultColDef: ColumnDef.defaultColDef,
            data: [],
            campaign_id: historyCampId
          },
        });
      }
      
    }

    if (
      response.data &&
      response.data.all_ly_year_data &&
      response.data.all_ly_year_data.length
    ) {
      let clonedLY = _.cloneDeep(response.data.all_ly_year_data);

      let lyHeaderCalc = ColumnDef.zpesLYHeader;
      // this.props.campaignType === "zpes"
      //   ? ColumnDef.zpesLYHeader
      //   : ColumnDef.LYHeader;

      // let pipelineLYHeader = lyHeaderCalc.map((item) => {
      //   if (
      //     item.field === "ly_adjusted_baseline_quantity" ||
      //     item.field === "ly_adjusted_promo_inc_quantity" ||
      //     item.field === "ly_adjusted_other_units" ||
      //     item.field === "ly_adjusted_total_quantity" ||
      //     item.field === "ly_adjusted_baseline_revenue" ||
      //     item.field === "ly_adjusted_promo_inc_revenue" ||
      //     item.field === "ly_adjusted_other_revenue" ||
      //     item.field === "ly_adjusted_total_revenue" ||
      //     item.field === "ly_adjusted_baseline_margin" ||
      //     item.field === "ly_adjusted_promo_inc_margin" ||
      //     item.field === "ly_adjusted_other_margin" ||
      //     item.field === "ly_adjusted_total_margin" ||
      //     item.field === "total_actual_units" ||
      //     item.field === "total_adjusted_units" ||
      //     item.field === "total_actual_sales" ||
      //     item.field === "total_adjusted_sales" ||
      //     item.field === "total_actual_margin" ||
      //     item.field === "total_adjusted_margin" ||
      //     item.field === "actual_gm" ||
      //     item.field === "adjusted_gm"
      //   ) {
      //     return {
      //       ...item,
      //       hide: headerCheck,
      //     };
      //   }
      //   return item;
      // });

      let responseDataLY = _.map(clonedLY, (item) => {
        item.getfun3 = this.showModal;
        item.campaign_type = findCampaignType && findCampaignType.campaign_type;
        return item;
      });

      this.setState({
        tableDataLY: {
          header: lyHeaderCalc, //pipelineLYHeader,
          defaultColDef: ColumnDef.defaultColDef,
          data: responseDataLY,
          campaign_id: historyCampId
        },
        zpesColStatus:
          findCampaignType && findCampaignType.campaign_type !== "future_dm"
            ? true
            : false,
        showZpesCol: true,
        setColStatus: headerCheck,
      });
    } else {
      if(reqType === 'All LY'){
        this.setState({
          tableDataLY: {
            header: ColumnDef.LYHeader,
            defaultColDef: ColumnDef.defaultColDef,
            data: [],
            campaign_id: historyCampId
          },
        });
      }
      
    }
  };

  zpesColHandler = () => {
    this.setState({ showZpesCol: false });
  };
  componentDidUpdate(oldProps, oldState) {
    selectedPromo = this.props.selectedPromo;
    campaignStartDateGlobal = this.props.campaignStartDate;
    campaignEndDateGlobal = this.props.campaignEndDate;

    if (
      this.props.selectedShop !== oldProps.selectedShop ||
      this.props.selectedBusinessDesc !== oldProps.selectedBusinessDesc ||
      this.props.selectedCustomerGropu !== oldProps.selectedCustomerGropu ||
      this.props.selectedPromo !== oldProps.selectedPromo ||
      this.props.campaignStartDate !== oldProps.campaignStartDate ||
      this.props.campaignEndDate !== oldProps.campaignEndDate
    ) {
      this.setState({
        simulateTabKey: "pipeline",
        couponTableData: [],
        disableCopyBtn: true,
      });
    }
    if (this.props.callPipelineTY) {
      this.props.callPipelineTYHandler();
      let {
        selectedPromo,
        selectedShop,
        selectedBusinessDesc,
        selectedCustomerGropu,
        adGrpArr,
        campaignStartDate,
        campaignEndDate,
      } = this.props;
      this.pipelineTYDataHandler(
        selectedPromo,
        campaignStartDate,
        campaignEndDate,
        selectedShop,
        selectedBusinessDesc,
        selectedCustomerGropu,
        adGrpArr
      );
    }
    if (oldProps.disableDeeperOfferBtn !== this.props.disableDeeperOfferBtn) {
      let cloned = this.state.cloned; let responseData;
      responseData = _.map(cloned, (item) => {
        item.disableDeeperOfferBtn = this.props.disableDeeperOfferBtn;
        return item;
      });
      this.setState({
        rowRefresh: true,
        tableDataTY: {
          header: this.state.pipelineTYHeader,
          defaultColDef: ColumnDef.defaultColDef,
          data: responseData,
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.campaignStartDate &&
      nextProps.campaignEndDate &&
      this.props.campaignStartDate !== nextProps.campaignStartDate &&
      this.props.campaignEndDate !== nextProps.campaignEndDate
    ) {
      let diffTime =
        Math.abs(
          new Date(nextProps.campaignStartDate).getTime() -
          new Date(nextProps.campaignEndDate).getTime()
        ) + 1;
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    if (
      nextProps.clearPipelineTable &&
      this.props.clearPipelineTable !== nextProps.clearPipelineTable
    ) {
      this.setState({
        // tableDataLY: [],
        // tableDataTY: [],
        tableDataLY: {
          header: [],
            defaultColDef: [],
            data: [],
        },
        tableDataTY: {
          header: [], 
            defaultColDef: [],
            data: [],
        },
        pipelineHeader: [],
      });
    }
    if (
      nextProps.getProductDetailsPromoId &&
      this.props.getProductDetailsPromoId !== nextProps.getProductDetailsPromoId
    ) {
      this.setState({
        skuChangeLogsTableData: nextProps.getProductDetailsPromoId.cy,
      });
    }
    if (nextProps.detailsBtnClicked) {
      localStorage.setItem("campaignId", this.props.historyCampaignId);
      localStorage.setItem("campaignName", this.props.historyCampaignName);
      localStorage.setItem("campStartDate", this.props.campaignStartDate);
      localStorage.setItem("campEndDate", this.props.campaignEndDate);
      localStorage.setItem("campMerchLockoutDate", this.props.campMerchLockoutDate);
      localStorage.setItem("campaignCreatedBy", this.props.createdBy );
      localStorage.setItem("campaignCreatedAt", this.props.createdAt );
      localStorage.setItem("campaignChangedBy", this.props.changedBy );
      localStorage.setItem("campaignChangedAt", this.props.changedAt );
    } else {
      localStorage.setItem("campaignId", "null");
      localStorage.setItem("campaignName", "null");
      localStorage.setItem("campStartDate", "null");
      localStorage.setItem("campEndDate", "null");
      localStorage.setItem("campMerchLockoutDate", "null");
      localStorage.setItem("campaignCreatedBy", "null");
      localStorage.setItem("campaignCreatedAt", "null");
      localStorage.setItem("campaignChangedBy", "null");
      localStorage.setItem("campaignChangedAt", "null");
    }
  }

  rowRefreshHandler = (index) => {
    this.setState({
      rowRefresh: !this.state.rowRefresh,
      rowIndex: index,
    });
  };
  setUserComments = (val, commentListCall) => {
    if (commentListCall) {
      this.props.userComments({
        promoId: [this.state.commentPromoId[0].promoId],
      });
    } else {
      let { commentPromoId } = this.state;
      _.map(commentPromoId, (promoDetails) => {
        promoDetails.promoId = val.promoId[0];
        promoDetails.adgroup_name = val.adgroup_name[0];
        promoDetails.offer_name = val.offer_name[0];
      });
      this.setState({ commentPromoId });
      this.props.userComments(val);
    }
  };

  showModal = (val) => {
    this.setState({
      showModelOpen: true,
      loaderAg: true,
    });

    setTimeout(() => {
      this.setState({
        loaderAg: false,
      });
    }, 2000);
  };

  showAdgroupChoiceModalMethod = (val) => {
    if (val) {
      this.setState({
        showAdgroupChoiceModal: true,
      });
    }
  };

  showModifyChoiceModalMethod = (val) => {
    if (val) {
      this.setState({
        showModifyChoiceModal: true,
      });
    }
  };

  setShowCopyOfferModal = async (val) => {
    if (val) {
      this.setState({ showLYLoader: true });
      let detailsRes = await this.props.getAdgroupOffer({
        promoIds: this.state.promoIdArray,
        startDate: campaignStartDateGlobal,
        endDate: campaignEndDateGlobal,
      });
      let tyEventsRes = await this.props.pipelineGetTyEvents({
        campaign_id: parseInt(localStorage.getItem("campaignId")),
      });

      if (detailsRes && tyEventsRes.status) {
        this.setState({
          tyEventsData: tyEventsRes.data,
          pipelineIntermediateData: detailsRes,
          showCopyOfferModal: true,
        });
      }
      this.setState({
        showLYLoader: false,
      });
    }
  };

  handleCloseCopyModal = () => {
    this.setState({
      showCopyOfferModal: false,
    });
  }


  deletePromoMethod = (deleteModalOpen, deleteModalData) => {
    if (deleteModalOpen) {
      this.setState({
        deleteModelOpen: true,
        deletePromoData: deleteModalData,
      });
    }
  };

  toggleView = (val) => {
    if (this.state.selectedView != val) {
      this.setState({ selectedView: val });
    }
  };

  closeAdgroupModal = (isSaved=false) => {

    if(this.state.promoIdArray && this.state.promoIdArray.length > 0 && !isSaved){
      this.setState({
        disableCopyBtn: false
      })
    }
    this.setState({
      showAddOffersFromLYModal: false,
      showEditOffersFromLYModal: false,
    });
  };

  closeBulkModifyModal = (isSaved=false) => {

    if(this.state.promoIdArray && this.state.promoIdArray.length > 0 && !isSaved){
      this.setState({
        disableCopyBtn: false
      })
    }
    this.setState({
      showBulkModifyModal: false
    })
  }

  valueValidator = (val) => {
    if (val === 0) return 0;
    else if (val) {
      let valCalc =
        typeof val === "string" && val.includes("$")
          ? val.replace("$", "")
          : val;
      return parseFloat(valCalc);
    }
    return null;
  };

  editPipelineCells = (rowDetails) => {
    promoId = rowDetails.promo_id;
    salesForecast = this.valueValidator(rowDetails.total_override_sales);
    unitForecast = this.valueValidator(rowDetails.total_override_units);
    marginForecast = this.valueValidator(rowDetails.total_override_margin);
    gmForecast = this.valueValidator(rowDetails.gm_override);

    if (
      unitForecast === null &&
      salesForecast === null &&
      marginForecast === null
    ) {
      gmForecast = null;
      this.setState({
        showSaveCommentModal: true,
      });
    } else if (
      unitForecast === null &&
      salesForecast === null &&
      marginForecast === null &&
      (gmForecast !== null || gmForecast !== 0)
    ) {
      gmForecast = null;
      this.setState({
        showSaveCommentModal: true,
      });
    } else if (salesForecast === 0 && marginForecast === 0) {
      gmForecast = 0;
      this.setState({
        showSaveCommentModal: true,
      });
    } else if (
      unitForecast === null &&
      (salesForecast !== null ||
        marginForecast !== null ||
        gmForecast !== null ||
        gmForecast !== 0)
    ) {
      this.setState({
        unitRequiredAlert: true,
      });

      setTimeout(() => {
        this.setState({
          unitRequiredAlert: false,
        });
      }, alertTimer);
    } else if (
      unitForecast === 0 &&
      ((salesForecast !== null && salesForecast !== 0) ||
        (marginForecast !== null && marginForecast !== 0))
    ) {
      this.setState({
        unitCantBeZero: true,
      });

      setTimeout(() => {
        this.setState({
          unitCantBeZero: false,
        });
      }, alertTimer);
    } else if (
      (salesForecast === null && marginForecast === null) ||
      (gmForecast === null && marginForecast === null) ||
      (salesForecast === null && gmForecast === null)
    ) {
      this.setState({
        threeFieldsAlert: true,
      });

      setTimeout(() => {
        this.setState({
          threeFieldsAlert: false,
        });
      }, alertTimer);
    } else {
      if (
        (rowDetails.prev_sales_override != rowDetails.total_override_sales ||
          rowDetails.prev_margin_override !=
          rowDetails.total_override_margin) &&
        rowDetails.prev_gm_override == rowDetails.gm_override &&
        rowDetails.total_override_sales !== null &&
        rowDetails.total_override_sales !== "" &&
        rowDetails.total_override_margin !== null &&
        rowDetails.total_override_margin !== ""
      ) {
        gmForecast =
          rowDetails.total_override_sales === 0
            ? 0
            : (rowDetails.total_override_margin /
              rowDetails.total_override_sales) *
            100;
      } else if (
        (rowDetails.prev_sales_override != rowDetails.total_override_sales ||
          rowDetails.prev_gm_override != rowDetails.gm_override) &&
        rowDetails.prev_margin_override == rowDetails.total_override_margin &&
        rowDetails.total_override_sales !== null &&
        rowDetails.total_override_sales !== "" &&
        rowDetails.gm_override !== null &&
        rowDetails.gm_override !== ""
      ) {
        marginForecast =
          (rowDetails.gm_override * rowDetails.total_override_sales) / 100;
      } else if (
        (rowDetails.prev_margin_override != rowDetails.total_override_margin ||
          rowDetails.prev_gm_override != rowDetails.gm_override) &&
        rowDetails.prev_sales_override == rowDetails.total_override_sales &&
        rowDetails.gm_override !== null &&
        rowDetails.gm_override !== "" &&
        rowDetails.total_override_margin !== null &&
        rowDetails.total_override_margin !== ""
      ) {
        salesForecast =
          rowDetails.gm_override === 0
            ? 0
            : (rowDetails.total_override_margin / rowDetails.gm_override) * 100;
      }

      if (gmForecast === null) {
        gmForecast =
          salesForecast === 0 ? 0 : (marginForecast / salesForecast) * 100;
      } else if (marginForecast === null) {
        marginForecast = (gmForecast * salesForecast) / 100;
      } else if (salesForecast === null) {
        salesForecast =
          gmForecast === 0 ? 0 : (marginForecast / gmForecast) * 100;
      }
      this.setState({
        showSaveCommentModal: true,
      });
    }
  };
  editableRowsHandler = (promoid) => {
    let { editableAdgroups } = this.state;
    editableAdgroups.push(promoid.promoId);
    this.setState({ editableAdgroups });
  };

  closeModal = () => {
    this.setState({
      showModelOpen: false,
    });
  };
  closeDeleteModal = () => {
    this.setState({ deleteModelOpen: false });
  };
  TYLYToggleHandler = async () => {
   
       this.setState({
        toggleLYSelected: !this.state.toggleLYSelected,
        showZpesCol: true,
      },async()=>{
        let campaignId = parseInt(localStorage.getItem("campaignId"));
      if((this.state.tableDataTY.data && ( this.state.tableDataTY.data.length === 0  || ( this.state.tableDataTY.campaign_id !== campaignId ) ) ) || (this.state.tableDataLY.data && ( this.state.tableDataLY.data.length === 0 ||  ( this.state.tableDataLY.campaign_id !== campaignId)   ) ) ){
        const shopArray = JSON.parse(localStorage.getItem("promo_shop"))
        const businessArray = JSON.parse(localStorage.getItem("promo_business"))
        const customerGroupArray = JSON.parse(localStorage.getItem("promo_customer_group"))
        const adgroupsArray = JSON.parse(localStorage.getItem("promo_adgroups"))
        const cStartDate = localStorage.getItem("promo_cstart_date")
        const cEndDate = localStorage.getItem("promo_cend_date")
         let a = await this.pipelineTYDataHandler(
          campaignId,
          cStartDate,
          cEndDate,
          shopArray,
          businessArray,
          customerGroupArray,
          adgroupsArray
        );
      }
      
    });
    
  };
  editAdgroup = (event) => {
    if (moment(event.data.end_date).isAfter(moment())) {
      if (
        event.column.colId === "adgroup_name_1" ||
        event.column.colId === "adgroup_name"
      ) {
        let campId = !_.isEmpty(this.state.pipelineDataTY)
          ? this.state.pipelineDataTY[0].campaign_id
          : this.props.selectedPromo;
        localStorage.setItem("campaignDetailsForCreate", campId);
        localStorage.setItem("campStartDate", this.state.campaignStartDate);
        localStorage.setItem("campEndDate", this.state.campaignEndDate);
        localStorage.setItem("campMerchLockoutDate", this.props.campMerchLockoutDate);
        this.props.call_edit_adgroup_in_createPromo(event.data);
        this.submitGA("Click", "Clicked_AdgroupButton");
      }
    }
  };

  onSelectionChanged = (rowCount) => {
    let adgroupDataArray = [];
    let promoIdVar = [];
    rowCount.map((adgroupData) => {
      adgroupDataArray.push(adgroupData.adgroup_id);
    });

    rowCount.map((adgroupData) => {
      promoIdVar.push(adgroupData.promo_id);
    });

    var selectedAdgroupId = [];

    selectedAdgroupCampaignId = rowCount.map((item) => {
      return item.campaign_id;
    });

    rowCount.map((item) => {
      selectedAdgroupId.push(item.adgroup_id);
    });

    this.setState({
      selectedAdgroupId: selectedAdgroupId,
      selectedCampaignId: selectedAdgroupCampaignId[0],
      adgroupDataArray: adgroupDataArray,
      promoIdArray: promoIdVar,
    });

    if (selectedAdgroupCampaignId.length !== 0) {
      this.setState({
        disableCopyBtn: false,
      });
    } else {
      this.setState({
        disableCopyBtn: true,
      });
    }
    // }
  };

  onCopyPromotions = () => {
    localStorage.setItem("campaignIdsForUrl", this.state.selectedCampaignId);
    localStorage.setItem("promoIdsForUrl", this.state.promoIdArray);
    localStorage.setItem("adgroupIdsForUrl", this.state.adgroupDataArray);

    this.props.history.push(`/createpromotion?copyCampaign=true`);
  };

  pipelineTableExportHandler = () => {
    this.setState({ pipelineTableExport: false });
  };
  onDownloadPipelineData = () => {
    this.setState({ pipelineTableExport: true });
  };
  tabHandler = async (key, promotionName) => {
    let { couponTableData } = this.state;
    if (promotionName && !_.size(couponTableData) && key === "coupon") {
      campaignStartDateGlobal = !_.isEmpty(this.state.getTYPipelineData)
        ? this.state.getTYPipelineData[0].campaign_start_date
        : "";
      campaignEndDateGlobal = !_.isEmpty(this.state.getTYPipelineData)
        ? this.state.getTYPipelineData[0].campaign_end_date
        : "";

      this.setState({
        couponLoading: true,
      });
      this.props.sendFiltersLoading(true, "pipe");

      let response = await this.props.getCouponList({
        couponId: "",
        campaignId: parseInt(localStorage.getItem("campaignId")),
      });

      this.props.sendFiltersLoading(false, "pipe");
      if (response.status) {
        this.setState({
          couponLoading: false,
        });
      }
      response.data.map((item) => {
        item.getfun = this.editCoupon;
        item.getfun1 = this.deleteCouponConfirm;
        item.getfun2 = this.showCouponModel;
        item.campaignStartDate = this.state.campaignStartDate;
        item.campaignEndDate = this.state.campaignEndDate;
        return item;
      });
      couponTableData = response.data;
    }
    // uncomment below to show deactvated offers
    if (promotionName && key === "deactivatedOffers") {
      // fetch deactivated offers data from API
      const deactivatedPromosReqObj = {
        campaign_id: parseInt(localStorage.getItem("campaignId"))
      }
      const result = await this.props.getDeactivatedPromos(deactivatedPromosReqObj)

      let tempTableData = {
        header: DeactivaleColumnDef.deactivatePromoColdef,
        defaultColDef: DeactivaleColumnDef.defaultColDef,
        data: result.deactivatedPromos
      }

      this.setState({
        deactiveTableData: tempTableData
      })
    }
    this.setState({ simulateTabKey: key, couponTableData });
  };
  editCoupon = (couponId) => {
    localStorage.setItem("campStartDate", campaignStartDateGlobal);
    localStorage.setItem("campEndDate", campaignEndDateGlobal);
    localStorage.setItem("edit_coupon_id", couponId);
    this.props.history.push(`/editcoupon`);
  };
  deleteCouponConfirm = (rowDetails) => {
    this.setState({
      showConfirmationModal: true,
      couponRowDetails: rowDetails,
    });
  };

  createNewAdgroup = () => {
    this.setState({
      showAdgroupChoiceModal: false,
    });
    this.props.history.push(`/createoffer`);
  };

  showChooseAlert = () => {
    this.setState({
      showAdgroupChoiceModal: !this.state.showAdgroupChoiceModal,
    });
  };

  showModifyOfferAlert = () => {
    this.setState({
      showModifyChoiceModal: !this.state.showModifyChoiceModal,
    });
  }

  addOffersFromLYModal = async () => {
    this.setState({ showLYLoader: true });
    let {
      selectedShop,
      selectedBusinessDesc,
      selectedCustomerGropu,
    } = this.props;
    let ly_start_date = localStorage.getItem("campaign_ly_start_date") === 'null' ? null : localStorage.getItem("campaign_ly_start_date") ;
    let ly_end_date = localStorage.getItem("campaign_ly_end_date") === 'null' ? null : localStorage.getItem("campaign_ly_end_date") ;
    let pipelineRes = await this.props.pipelineIntermediateApi({
      campaign_id: parseInt(localStorage.getItem("campaignId")),
      h3: selectedShop,
      h5: selectedBusinessDesc,
      h6: selectedCustomerGropu,
      ly_start_date,
      ly_end_date
    });
    let tyEventsRes = await this.props.pipelineGetTyEvents({
      campaign_id: parseInt(localStorage.getItem("campaignId")),
    });

    if (pipelineRes.status && tyEventsRes.status) {
      this.setState({
        tyEventsData: tyEventsRes.data,
        pipelineIntermediateData: pipelineRes.data,
        showAdgroupChoiceModal: false,
        showAddOffersFromLYModal: true,
      });
    }
    this.setState({
      showAdgroupChoiceModal: false,
      showAddOffersFromLYModal: true,
      showLYLoader: false,
    });
  };

  editOffersFromLYModal = async () => {
    this.setState({ showLYLoader: true, showModifyChoiceModal: false });
    console.log("this.props",this.props);
    let detailsRes = await this.props.getAdgroupOffer({
      promoIds: this.state.promoIdArray,
      startDate: campaignStartDateGlobal,
      endDate: campaignEndDateGlobal,
    });
    console.log("🚀 ~ editOffersFromLYModal= ~ detailsRes:", detailsRes)
    let tyEventsRes = await this.props.pipelineGetTyEvents({
      campaign_id: parseInt(localStorage.getItem("campaignId")),
    });

    if (detailsRes && tyEventsRes.status) {
      this.setState({
        tyEventsData: tyEventsRes.data,
        pipelineIntermediateData: detailsRes,
        showAdgroupChoiceModal: false,
        showEditOffersFromLYModal: true,
        disableCopyBtn: true,
      });
    }
    this.setState({
      showAdgroupChoiceModal: false,
      showEditOffersFromLYModal: true,
      showLYLoader: false,
    });
  };

  bulkModifyOffers = async () => {
    this.setState({ showLYLoader: true, showModifyChoiceModal: false });
    let detailsRes = await this.props.getAdgroupOffer({
      promoIds: this.state.promoIdArray,
      startDate: campaignStartDateGlobal,
      endDate: campaignEndDateGlobal,
    });
    let tyEventsRes = await this.props.pipelineGetTyEvents({
      campaign_id: parseInt(localStorage.getItem("campaignId")),
    });

    if (detailsRes && tyEventsRes.status) {
      this.setState({
        tyEventsData: tyEventsRes.data,
        pipelineIntermediateData: detailsRes,
        showBulkModifyModal: true,
        disableCopyBtn: true,
      });
    }
    this.setState({
      showLYLoader: false,
    });
  }

  deleteCoupon = async (rowDetails) => {
    let response = await this.props.deleteCoupons({
      couponId: [rowDetails.coupon_id],
    });

    if (response.status) {
      this.setState({
        deletedCouponAlert: true,
        showConfirmationModal: false,
      });

      let { couponTableData } = this.state;

      let responseList = await this.props.getCouponList({
        couponId: "",
        campaignId: parseInt(localStorage.getItem("campaignId")),
      });

      responseList.data.map((item) => {
        item.getfun = this.editCoupon;
        item.getfun1 = this.deleteCouponConfirm;
        item.getfun2 = this.showCouponModel;
        item.campaignStartDate = this.state.campaignStartDate;
        item.campaignEndDate = this.state.campaignEndDate;
        return item;
      });
      couponTableData = responseList.data;

      this.setState({ couponTableData });

      setTimeout(() => {
        this.setState({
          deletedCouponAlert: false,
        });
      }, alertTimer);
    }
  };
  showCouponModel = () => {
    this.setState({ showModelOpen: true });
  };
  saveCommentString = async (commentString) => {
    this.props.usersLogReport({
      screen: "pipeline",
      action: "forecast_override",
      userId: localStorage.getItem("email"),
    });

    let res = await this.props.savePipelineCellsMethod({
      promo_id: promoId,
      original_update_flag: original_update_flag,
      comment_text: commentString,
      unit_forecast: unitForecast,
      sales_forecast: salesForecast,
      margin_forecast: marginForecast,
      gm_forecast: gmForecast,
    });
    if (res.status) {
      this.setState({
        disableCopyBtn: true,
      });
      this.pipelineTYDataHandler(
        selectedPromo,
        campaignStartDateGlobal,
        campaignEndDateGlobal,
        this.props.selectedShop,
        this.props.selectedBusinessDesc,
        this.props.selectedCustomerGropu,
        ""
      );
      this.setState({ rowSavedAlert: true, toggleLYSelected: false });

      setTimeout(() => {
        this.setState({ rowSavedAlert: false });
      }, alertTimer);
    }
  };

  closeCommentModal = () => {
    this.setState({
      showSaveCommentModal: false,
    });
  };

  updatePipelineData = (adgroupId, from, editOffers) => {
    if (from !== "intermediate") {
      let filteredData = _.filter(this.state.deletePromoData, (item) => {
        return !_.includes(adgroupId, item.adgroup_id);
      });

      this.setState({
        deletePromoData: filteredData,
        showAdgroupDeleted: true,
      });

      setTimeout(() => {
        this.setState({
          showAdgroupDeleted: false,
        });
      }, alertTimer);
    }
    this.pipelineTYDataHandler(
      selectedPromo,
      campaignStartDateGlobal,
      campaignEndDateGlobal,
      this.props.selectedShop,
      this.props.selectedBusinessDesc,
      this.props.selectedCustomerGropu,
      ""
    );
    this.props.onDmEditHandler("intermediate", editOffers);
  };

  showConfirmAlert = () => {
    this.setState({ showConfirmationModal: false });
  };
  onUploadOffers = () => {
    this.props.history.push("/uploadOffers");
  }
  submitGA(action, label) {
    PromotionCalendarGA({ action, label });
  }

  render() {
    let dataTable = (
      <div>
        <div className="alertDivs">
          {/* {this.state.showAdgroupDeleted ? (
            <div>
              <AlertSuccess
                message="Deleted successfully!"
                show={this.state.showAdgroupDeleted}
              />
            </div>
          ) : null} */}
        </div>
        <Tabs
          id="controlled-tab-example"
          activeKey={this.state.simulateTabKey}
          style={{ overflowY: "hidden", marginTop: "-2.5%" }}
          onSelect={(key) => {
            this.tabHandler(key, this.state.promotionName);
            this.submitGA("Click", "Clicked_Adgroup&CouponToggleButton");
          }}
        >
          <Tab eventKey="pipeline" title="Adgroup">
            {!_.isEmpty(this.state.tableDataTY.data) ||
              (this.props.detailsBtnClicked && this.state.campaignStartDate) ? (
                <>
              <div className="row pipelineCopydiv">
                <div className="row col-md-8 p-0">
                  <div className="row col-md-9" style={{ marginLeft: "12px" }}>
                    <div className="col-5">
                      <p className="promoNameText row">
                        Promotion Name : &nbsp;&nbsp;
                        <span>
                          <p className="promonameResult pl-0">
                            {/* {this.state.promotionName} */}
                            {/* {`${getBrowserTimeZone(this.state.campaignStartDate,"MM/DD/YYYY")} - ${getBrowserTimeZone(this.state.campaignEndDate,"MM/DD/YYYY")}`} */}
                            {`${moment(this.state.campaignStartDate).format("MM/DD/YYYY")} - ${moment(this.state.campaignEndDate).format("MM/DD/YYYY")}`}
                          </p>
                        </span>
                      </p>
                    </div>
                    <div className="col">
                      <p
                        className="promoNameText row d-block"
                        style={{ marginLeft: "10px" }}
                      >
                        Start Date : <br />
                        <p
                          className="promonameResult"
                          style={{ paddingLeft: "0" }}
                        >
                          {/* {getBrowserTimeZone(this.state.campaignStartDate,"MM/DD/YYYY")} */}
                          {this.state.campaignStartDate}
                        </p>
                      </p>
                    </div>
                    <div className="col">
                      <p
                        className="promoNameText row d-block"
                        style={{ marginLeft: "10px" }}
                      >
                        End Date :
                        <br />
                        <p className="promonameResult pl-0">
                          {/* {getBrowserTimeZone(this.state.campaignEndDate,"MM/DD/YYYY") } */}
                          {this.state.campaignEndDate}
                        </p>
                      </p>
                    </div>
                    <div className="col">
                      <p
                        className="promoNameText"
                        style={{ marginLeft: "10px" }}
                      >
                        # of days :
                        <br />
                        <p className="promonameResult pl-0">
                          {this.state.diffDays ? this.state.diffDays : ""}
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="row col-md-3 p-0">
                    <div
                      id="pipelineCal"
                      className="div-pipeline btn-group text-center"
                    >
                      <label>
                        <span
                          className={
                            this.state.toggleLYSelected
                              ? ""
                              : "toggle-active-class"
                          }
                        >
                          All TY
                        </span>

                        <Toggle
                          defaultChecked={this.state.toggleLYSelected}
                          icons={false}
                          onChange={(e) => this.TYLYToggleHandler(e)}
                          disabled={this.props.disableDeeperOfferBtn}
                        />
                        <span
                          className={
                            this.state.toggleLYSelected
                              ? "toggle-active-class"
                              : ""
                          }
                        >
                          All LY
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-4 pl-0 p-0 pr-2"
                  style={{ margin: "6px 0 0 24px" }}
                >
                  <PipelineActionButtons
                    disableCopyBtn={this.state.disableCopyBtn}
                    onCopyPromotions={this.onCopyPromotions}
                    pipelineDataTY={this.state.tableDataTY.data}
                    pipelineDataLY={this.state.tableDataLY.data}
                    toggleLYSelected={this.state.toggleLYSelected}
                    campaignStartDate={this.state.campaignStartDate}
                    campaignEndDate={this.state.campaignEndDate}
                    selectedPromo={this.props.selectedPromo}
                    showAdgroupChoiceModalMethod={
                      this.showAdgroupChoiceModalMethod
                    }
                    // disableCopyBtn={this.state.disableCopyBtn}
                    promoIdArray={this.state.promoIdArray}
                    deletePromoMethod={this.deletePromoMethod}
                    onDownloadPipelineData={this.onDownloadPipelineData}
                    history={this.props.history}
                    editOffersFromLYModal={this.editOffersFromLYModal}
                    showModifyChoiceModalMethod={this.showModifyChoiceModalMethod}
                    setShowCopyOfferModal={this.setShowCopyOfferModal}
                    handleModifyOffersCSV={this.onUploadOffers}
                  />
                </div>
              </div>
              <div className="row pipelineCopydiv" style={{marginLeft: 0}}>
              <div className="col">
                      <p className="promoNameText row d-block">
                        Created By : {" "}
                        <span>
                          <p className="promonameResult pl-0">
                            {localStorage.getItem("campaignCreatedBy")}
                          </p>
                        </span>
                      </p>
                    </div>
                    <div className="col">
                      <p className="promoNameText row d-block">
                      Created At : {" "}
                        <span>
                          <p className="promonameResult pl-0">
                          {localStorage.getItem("campaignCreatedAt") !== 'null' ? getBrowserTimeZone(localStorage.getItem("campaignCreatedAt"),"MONTH") : "-"}
                          </p>
                        </span>
                      </p>
                    </div>
                    <div className="col">
                      <p className="promoNameText row d-block">
                        Changed By : {" "}
                        <span>
                          <p className="promonameResult pl-0">
                          {localStorage.getItem("campaignChangedBy")}
                          </p>
                        </span>
                      </p>
                    </div>
                    <div className="col">
                      <p className="promoNameText row d-block">
                        Changed At : {" "}
                        <span>
                          <p className="promonameResult pl-0">
                          {localStorage.getItem("campaignChangedAt") === localStorage.getItem("campaignCreatedAt") ? "-" : getBrowserTimeZone(localStorage.getItem("campaignChangedAt"),"MONTH")}
                          </p>
                        </span>
                      </p>
                    </div>
              </div>
              </>
            ) : null}

            <div className="pipeline-grid-div pipeline-ag-grid">
              <DataTable
                parentId="myGridForPipeline"
                parentStyle={{
                  height: _.isEmpty(this.state.tableDataTY.data)
                    ? "calc(100vh - 400px)"
                    : !_.isEmpty(this.state.tableDataTY.data) &&
                      this.state.tableDataTY.data.length <= 7
                      ? "calc(100vh - 450px)"
                      : "calc(100vh - 380px)",
                  width: "100%",
                  position: "relative",
                }}
                parentClass="ag-theme-balham"
                tableData={
                  this.state.toggleLYSelected
                    ? this.state.tableDataLY
                    : this.state.tableDataTY
                }
                rowClassRules={this.state.rowClassRules}
                getRowStyle={this.state.getRowStyle}
                frameworkComponents={this.state.frameworkComponents}
                context={this.state.context}
                rowClass={
                  this.state.toggleLYSelected
                    ? "pipelineCheckboxDisabled"
                    : ''
                }
                onCellClicked={this.editAdgroup}
                rowSelection={this.state.rowSelection}
                suppressRowClickSelection={true}
                selectOfferRow={this.onSelectionChanged}
                overlayNoRowsTemplate='<span class="pipeline-span"> No offers to display</span>'
                pipelineTableExport={this.state.pipelineTableExport}
                pipelineTableExportHandler={this.pipelineTableExportHandler}
                startDate={this.state.campaignStartDate}
                endDate={this.state.campaignEndDate}
                editableRowsHandler={this.editableRowsHandler}
                autoSizeCol={true}
                rowRefresh={this.state.rowRefresh}
                rowRefreshHandler={this.rowRefreshHandler}
                rowIndex={this.state.rowIndex}
                showZpesCol={this.state.showZpesCol}
                zpesColStatus={this.state.zpesColStatus}
                zpesColHandler={this.zpesColHandler}
                setColStatus={this.state.setColStatus}
                disableDeeperOfferBtn={this.props.disableDeeperOfferBtn}
              />
            </div>
            {!_.isEmpty(this.state.tableDataTY.data) ? (
              <PipelineFootnote />
            ) : null}
          </Tab>
          <Tab eventKey="coupon" title="Coupon">
            <CouponPipeline
              pipelineDataAdGroup={this.state.tableDataTY}
              calendarDataPerYear={this.props.calendarDataPerYear}
              promotionName={this.state.promotionName}
              campaignStartDate={this.state.campaignStartDate}
              campaignEndDate={this.state.campaignEndDate}
              diffDays={this.state.diffDays}
              tableData={this.state.couponTableData}
            />
          </Tab>
          <Tab eventKey="deactivatedOffers" title="Deactivated offers">
            <div className="row pipelineCopydiv">
              <div className="row col-md-9 p-0">
                <div className="row col-md-8" style={{ marginLeft: "12px" }}>
                  <div className="col-5">
                    <p className="promoNameText row">
                      Promotion Name:&nbsp;&nbsp;
                      <span>
                        <p className="promonameResult pl-0">
                          {/* {this.state.promotionName} */}
                          {`${moment(this.state.campaignStartDate).format("MM/DD/YYYY")} - ${moment(this.state.campaignEndDate).format("MM/DD/YYYY")}`}
                        </p>
                      </span>
                    </p>
                  </div>
                  <div className="col">
                    <p
                      className="promoNameText row d-block"
                      style={{ marginLeft: "10px" }}
                    >
                      Start Date: <br />
                      <p
                        className="promonameResult"
                        style={{ paddingLeft: "0" }}
                      >
                        {this.state.campaignStartDate}
                      </p>
                    </p>
                  </div>
                  <div className="col">
                    <p
                      className="promoNameText row d-block"
                      style={{ marginLeft: "10px" }}
                    >
                      End Date:
                      <br />
                      <p className="promonameResult pl-0">
                        {this.state.campaignEndDate}
                      </p>
                    </p>
                  </div>
                  <div className="col">
                    <p
                      className="promoNameText"
                      style={{ marginLeft: "10px" }}
                    >
                      # of days:
                      <br />
                      <p className="promonameResult pl-0">
                        {this.state.diffDays ? this.state.diffDays : ""}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <div className="pipeline-grid-div pipeline-ag-grid">
                <DataTable
                  tableData={this.state.deactiveTableData}
                  // autoSizeCol={true}
                  // rowSelection="multiple"
                  suppressRowClickSelection={true}
                />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    );
    return (
      <div>
        <div className="promo cardGap">
          <div className="alertDivs">
            {this.state.rowSavedAlert ? (
              <AlertSuccess
                message="Changes Saved Successfully."
                show={this.state.rowSavedAlert}
              />
            ) : null}

            {this.state.deletedCouponAlert ? (
              <AlertSuccess
                message="Coupon deleted successfully!"
                show={this.state.deletedCouponAlert}
              />
            ) : null}

            {this.state.unitCantBeZero ? (
              <AlertDanger
                message="Unit override cannot be zero when other overrides are not zero!"
                show={this.state.unitCantBeZero}
              />
            ) : null}

            {this.state.unitRequiredAlert ? (
              <AlertDanger
                message="Override units is mandatory!"
                show={this.state.unitRequiredAlert}
              />
            ) : null}

            {this.state.threeFieldsAlert ? (
              <AlertDanger
                message="Please input unit, margin and sales forecast!!"
                show={this.state.threeFieldsAlert}
              />
            ) : null}
          </div>

          <div className="card CalendarMain promoCalendarDiv1">
            <h4 className="promoHeading">Promotion Pipeline</h4>
            {dataTable}
          </div>
        </div>

        {this.state.showSaveCommentModal ? (
          <CommentModal
            sendCommentValue={(commentString) => {
              this.saveCommentString(commentString);
            }}
            closeDeleteModal={this.closeCommentModal}
          />
        ) : null}

        {this.state.showModelOpen ? (
          <UserComments
            showModelOpen={this.state.showModelOpen}
            closeModal={this.closeModal}
            promoUserComments={this.props.promoUserComments}
            loaderAg={this.state.loaderAg}
            commentPromoId={this.state.commentPromoId}
            commentListCall={this.setUserComments}
          />
        ) : null}

        {this.state.showConfirmationModal ? (
          <DeleteCouponConfirmationModal
            couponRowDetails={this.state.couponRowDetails}
            deleteCoupon={this.deleteCoupon}
            showConfirmAlert={this.showConfirmAlert}
          />
        ) : null}
        {this.state.deleteModelOpen ? (
          <DeleteAdgroup
            closeDeleteModal={this.closeDeleteModal}
            tableData={this.state.deletePromoData}
            campaignStartDate={this.state.campaignStartDate}
            campaignEndDate={this.state.campaignEndDate}
            promotionName={this.state.promotionName}
            updatePipelineData={this.updatePipelineData}
          />
        ) : null}

        {this.state.showAdgroupChoiceModal ? (
          <AdgroupChoiceModal
            handleFirstButtonClick={this.addOffersFromLYModal}
            handleSecondButtonClick={this.createNewAdgroup}
            showChooseAlert={this.showChooseAlert}
            handleUploadClick={this.onUploadOffers}
            fisrtBtnText={"Add Offers From LY"}
            secondBtnText={"Add New Offer"}
            uploadText={"Upload Offers"}
            isShowUploadBtn={true}
          />
        ) : (
          <div />
        )}

        {this.state.showModifyChoiceModal ? (
          <AdgroupChoiceModal
            handleFirstButtonClick={this.editOffersFromLYModal}
            handleSecondButtonClick={this.bulkModifyOffers}
            showChooseAlert={this.showModifyOfferAlert}
            handleUploadClick={this.onUploadOffers}
            fisrtBtnText={"Modify Offers"}
            secondBtnText={"Modify Offers in Bulk"}
            uploadText={"Modify Offers through CSV"}
            firstBtndisabled={false}
            secondBtndisabled={false}
            uploadBtndisabled={true}
            isShowUploadBtn={false}
          />
        ) : (
          <div />
        )}

        {this.state.showCopyOfferModal ?
          <CopyOfferModal
            handleCloseCopyModal={this.handleCloseCopyModal}
            pipelineIntermediateData={this.state.pipelineIntermediateData}
            updatePipelineData={this.updatePipelineData}
            campaignStartDate={this.state.campaignStartDate}
            campaignEndDate={this.state.campaignEndDate}
            promotionName={this.state.promotionName}
          /> :
          <div />
        }

        {(this.state.showAddOffersFromLYModal || this.state.showEditOffersFromLYModal) ? (
          <AddOffersFromLYModal
            closeAdgroupModal={this.closeAdgroupModal}
            campaignId={this.state.campaignId}
            campaignStartDate={this.state.campaignStartDate}
            campaignEndDate={this.state.campaignEndDate}
            campaignName={this.props.selectedPromoName}
            pipelineIntermediateData={this.state.pipelineIntermediateData}
            tyEventsData={this.state.tyEventsData}
            diffDays={this.state.diffDays}
            history={this.props.history}
            updatePipelineData={this.updatePipelineData}
            editOffers={this.state.showEditOffersFromLYModal}
          />
        ) : (
          <div />
        )}

        {this.state.showBulkModifyModal &&
          <BulkModifyOffersModal
            closeBulkModifyModal={this.closeBulkModifyModal}
            campaignId={this.state.campaignId}
            campaignStartDate={this.state.campaignStartDate}
            campaignEndDate={this.state.campaignEndDate}
            campaignName={this.props.selectedPromoName}
            diffDays={this.state.diffDays}
            pipelineIntermediateData={this.state.pipelineIntermediateData}
            tyEventsData={this.state.tyEventsData}
            history={this.props.history}
            updatePipelineData={this.updatePipelineData}
          />
        }

        {this.state.showLYLoader ? (
          <Modal visible={true} width="90%" height="86%" effect="fadeInDown">
            <PageLoader loader={this.state.showLYLoader}>
              <div style={{ width: "90%", height: "86%" }}></div>
            </PageLoader>
          </Modal>
        ) : null}
      </div>
    );
  }
}

PromotionPipeline.propTypes = {
  getCopyCampaignDetails: PropTypes.func.isRequired,
  userComments: PropTypes.func.isRequired,
  savePipelineCellsMethod: PropTypes.func.isRequired,
  getCouponList: PropTypes.func.isRequired,
  deleteCoupons: PropTypes.func.isRequired,
  pipelineGetTyEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pipelineData: state.calendar.pipelineData,
  savePipelineCells: state.calendar.savePipelineCells,
  promoUserComments: state.calendar.userComments,
  calendarDataPerYear: state.calendar.calendarDataPerYear,
  disableDeeperOfferBtn: state.userDataReducer.disableDeeperOfferBtn,
});

export default connect(mapStateToProps, {
  getTYPipelineData,
  getCopyCampaignDetails,
  userComments,
  savePipelineCellsMethod,
  getCouponList,
  deleteCoupons,
  pipelineIntermediateApi,
  pipelineGetTyEvents,
  usersLogReport,
  getAdgroupOffer,
  getDeactivatedPromos ,
  getSignShellOptions
})(withRouter(PromotionPipeline));
