import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import { Collapse, Button, Modal, Typography, Result, Tag } from "antd";
import { CloseCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Row, Col } from "react-bootstrap";
import IncludeAttributes from "./IncludeAttributes";
import ExcludeAttributes from "./ExcludeAttributes";
import IncludeSku from "./IncludeSku";
import ExcludeSku from "./ExcludeSku";
import SkuModalForAdgroupCreate from "../../Adgroup/SkuModalForAdgroupCreate";
import AlertDanger from "../../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../../utils/AlertMessage/alertSuccess";

// import PageLoader from "../../../../../utils/Loader";

import {
  productListFilters,
  getValidCollections,
  validateAdgroup,
  createAdgroupV2,
  updateAdgroupV2,
  getSkuListFromSkuIds,
  // getAllHierarchies,
  getMerchIdHierarchyMapping
} from "../../../../../redux/actions/adgroup";
import "./skuSelectionMaster.css";
import { isIt } from "../../../../../utils/getRoleName";
import PageLoader from "../../../../../utils/Loader";

const { Paragraph, Text } = Typography;

// const {
//   MERCHANDISE_HIERARCHY_LIST,

//   MERCH_CAT_OPTIONS,
//   CATEGORY_OPTIONS,
//   SHOP_DESCRIPTION_OPTIONS,
//   AREA_OPTIONS,
//   BUSINESS_OPTIONS,

//   CUSTOMERGROUP_OPTIONS,
//   // getHighHierarchyMapping
// } = require("../utils/adgroup-constants");

class SkuSelectionMaster extends Component {
  state = {
    collectionOptions: [],
    articleStatusOptions: [],
    customerGroupOptions:[],
    businessOptions:[],
    shopOptions:[],
    categoryOptions:[],
    merchantCategoryOptions:[],
    notAvaliableCustomerGroupOptions:[],
    notAvaliableBusinessOptions:[],
    notAvaliableShopOptions:[],
    notAvaliableCategoryOptions:[],
    notAvaliableMerchantCategoryOptions:[],
    collectionOptions : [],
    vendorOptions : [],
    articleStatusOptions : [],
    productBrandOptions : [],
    attributeColorOptions : [],
    attributeFabricOptions : [],
    attributeSizeOptions : [],
    attributeThemeOptions:[],
    adgroupValidationObj: {},
    selectedSKU_INC: [],
    selectedSKU_EXC: [],
    modalOpen: false,
    adgroupSaved: false,
    isSuccess: false,
    successMessage: '',
    isFailure: false,
    failureMessage: "",
    showViewSkuModal: false,
    skuModalLoading: false,
    SKUTableData: [],
    // --------------------------------------------------INCLUDE ATTRIBUTE STATE
    selectedCustomerGroup_INC_ATTR: undefined,
    selectedBusiness_INC_ATTR: undefined,
    selectedArea_INC_ATTR: undefined,
    selectedShopDesc_INC_ATTR: undefined,
    selectedCategory_INC_ATTR: undefined,
    selectedMerchCat_INC_ATTR: undefined,

    selectedCharCollection_INC_ATTR: undefined,
    selectedVendor_INC_ATTR: undefined,
    selectedArticleStatus_INC_ATTR: undefined,
    selectedRppMinValue_INC_ATTR: null,
    selectedRppMaxValue_INC_ATTR: null,
    selectedRppSpfc_INC_ATTR: undefined,

    selectedAttrColor_INC_ATTR: undefined,
    selectedAttrFabric_INC_ATTR: undefined,
    selectedAttrSize_INC_ATTR: undefined,
    selectedAttributeTheme_INC_ATTR: undefined,
    selectedProductBrand_INC_ATTR: undefined,
    availableCustomerGroupOptions_INC_ATTR: [],
    availableBusinessOptions_INC_ATTR: [],
    availableAreaOptions_INC_ATTR: [],
    availableShopDescOptions_INC_ATTR: [],
    availableCategoryOptions_INC_ATTR: [],
    availableMerchCatOptions_INC_ATTR: [],
    /*
    availableCustomerGroupOptions_INC_ATTR: CUSTOMERGROUP_OPTIONS,
    availableBusinessOptions_INC_ATTR: BUSINESS_OPTIONS,
    availableAreaOptions_INC_ATTR: AREA_OPTIONS,
    availableShopDescOptions_INC_ATTR: SHOP_DESCRIPTION_OPTIONS,
    availableCategoryOptions_INC_ATTR: CATEGORY_OPTIONS,
    availableMerchCatOptions_INC_ATTR: MERCH_CAT_OPTIONS,
    */
    // ---------------------------------------------------EXCLUDE ATTRIBUTE STATE
    selectedCustomerGroup_EXC_ATTR: undefined,
    selectedBusiness_EXC_ATTR: undefined,
    selectedArea_EXC_ATTR: undefined,
    selectedShopDesc_EXC_ATTR: undefined,
    selectedCategory_EXC_ATTR: undefined,
    selectedMerchCat_EXC_ATTR: undefined,

    selectedCharCollection_EXC_ATTR: undefined,
    selectedVendor_EXC_ATTR: undefined,
    selectedArticleStatus_EXC_ATTR: undefined,
    selectedRppMinValue_EXC_ATTR: null,
    selectedRppMaxValue_EXC_ATTR: null,
    selectedRppSpfc_EXC_ATTR: undefined,

    selectedAttrColor_EXC_ATTR: undefined,
    selectedAttrFabric_EXC_ATTR: undefined,
    selectedAttrSize_EXC_ATTR: undefined,
    selectedAttributeTheme_EXC_ATTR: undefined,
    selectedProductBrand_EXC_ATTR: undefined,

    availableCustomerGroupOptions_EXC_ATTR: [],
    availableBusinessOptions_EXC_ATTR: [],
    availableAreaOptions_EXC_ATTR: [],
    availableShopDescOptions_EXC_ATTR: [],
    availableCategoryOptions_EXC_ATTR: [],
    availableMerchCatOptions_EXC_ATTR: [],
    // ---------------------------------------------------INCLUDE SKU STATE
    selectedCustomerGroup_INC_SKU: undefined,
    selectedBusiness_INC_SKU: undefined,
    selectedArea_INC_SKU: undefined,
    selectedShopDesc_INC_SKU: undefined,
    selectedCategory_INC_SKU: undefined,
    selectedMerchCat_INC_SKU: undefined,

    selectedCharCollection_INC_SKU: undefined,
    selectedVendor_INC_SKU: undefined,
    selectedArticleStatus_INC_SKU: undefined,
    selectedRppMinValue_INC_SKU: null,
    selectedRppMaxValue_INC_SKU: null,
    selectedRppSpfc_INC_SKU: undefined,

    selectedAttrColor_INC_SKU: undefined,
    selectedAttrFabric_INC_SKU: undefined,
    selectedAttrSize_INC_SKU: undefined,
    selectedAttributeTheme_INC_SKU: undefined,
    selectedProductBrand_INC_SKU: undefined,

    // availableCustomerGroupOptions_INC_SKU: CUSTOMERGROUP_OPTIONS,
    // availableBusinessOptions_INC_SKU: BUSINESS_OPTIONS,
    // availableAreaOptions_INC_SKU: AREA_OPTIONS,
    // availableShopDescOptions_INC_SKU: SHOP_DESCRIPTION_OPTIONS,
    // availableCategoryOptions_INC_SKU: CATEGORY_OPTIONS,
    // availableMerchCatOptions_INC_SKU: MERCH_CAT_OPTIONS,

    selectedArticlesIds_INC_SKU: undefined,
    // ---------------------------------------------------EXCLUDE SKU STATE
    selectedCustomerGroup_EXC_SKU: undefined,
    selectedBusiness_EXC_SKU: undefined,
    selectedArea_EXC_SKU: undefined,
    selectedShopDesc_EXC_SKU: undefined,
    selectedCategory_EXC_SKU: undefined,
    selectedMerchCat_EXC_SKU: undefined,

    selectedCharCollection_EXC_SKU: undefined,
    selectedVendor_EXC_SKU: undefined,
    selectedArticleStatus_EXC_SKU: undefined,
    selectedRppMinValue_EXC_SKU: null,
    selectedRppMaxValue_EXC_SKU: null,
    selectedRppSpfc_EXC_ATTR: undefined,

    selectedAttrColor_EXC_SKU: undefined,
    selectedAttrFabric_EXC_SKU: undefined,
    selectedAttrSize_EXC_SKU: undefined,
    selectedAttributeTheme_EXC_SKU: undefined,
    selectedProductBrand_EXC_SKU: undefined,

    // availableCustomerGroupOptions_EXC_SKU: CUSTOMERGROUP_OPTIONS,
    // availableBusinessOptions_EXC_SKU: BUSINESS_OPTIONS,
    // availableAreaOptions_EXC_SKU: AREA_OPTIONS,
    // availableShopDescOptions_EXC_SKU: SHOP_DESCRIPTION_OPTIONS,
    // availableCategoryOptions_EXC_SKU: CATEGORY_OPTIONS,
    // availableMerchCatOptions_EXC_SKU: MERCH_CAT_OPTIONS,

    selectedArticlesIds_EXC_SKU: undefined,
    joann_cutover: false
  };

  async componentDidMount() {
    // const articleStatusData = _.get(
    //   await this.props.productListFilters({
    //     required_column: "article_status",
    //     searchKey: ""
    //   })
    // , 'data[0].article_status', []);

    // const get_all_hierarchies = _.get(
    //   await this.props.getAllHierarchies(),
    //   "data",
    //   []
    // );

    /*
    const collectionData = _.get(
      await this.props.getValidCollections({}),
      "data[0].collectionNames",
      []
    );


    const articleStatusOptions = [
      {
        "value": 10,
        "label": 10
      },
      {
        "value": 20,
        "label": 20
      },
      {
        "value": 22,
        "label": 22
      },
      {
        "value": 30,
        "label": 30
      },
      {
        "value": 35,
        "label": 35
      },
      {
        "value": 40,
        "label": 40
      },
      {
        "value": 60,
        "label": 60
      }
     ]

    const collectionOptions = _.map(collectionData, (row) => {
      return { value: row, label: row };
    });

    


    this.setState({
      articleStatusOptions: articleStatusOptions,
      collectionOptions: collectionOptions
    },()=>{
      console.log("Updated",this.state)
    });
    */
  }

  componentWillReceiveProps(nextProps) {
    const pageType = _.get(this.props, "pageType", "create");
    // const existingAdgroupData = _.get(this.props, 'existingAdgroupData', null);

    if (
      nextProps.existingAdgroupData !== this.props.existingAdgroupData &&
      _.eq(pageType, "edit") &&
      !_.isEmpty(nextProps.existingAdgroupData)
    ) {
      const attr_inclusion = _.get(
        nextProps.existingAdgroupData,
        "selections.attr_inclusion",
        {}
      );
      const attr_exclusion = _.get(
        nextProps.existingAdgroupData,
        "selections.attr_exclusion",
        {}
      );
      const sku_inclusion = _.get(
        nextProps.existingAdgroupData,
        "selections.sku_inclusion",
        {}
      );
      const sku_exclusion = _.get(
        nextProps.existingAdgroupData,
        "selections.sku_exclusion",
        {}
      );

      const sku_inc = _.get(
        nextProps.existingAdgroupData,
        "selections.sku_inc",
        []
      );
      const sku_exc = _.get(
        nextProps.existingAdgroupData,
        "selections.sku_exc",
        []
      );

      // console.log('componentWillReceiveProps() nextProps:', nextProps);
      // console.log('componentWillReceiveProps() this.props:', this.props);
      // console.log('componentWillReceiveProps() attr_inclusion:', attr_inclusion);
      this.setState(
        {
          selectedSKU_INC: sku_inc,
          selectedSKU_EXC: sku_exc,
          // --------------------------------------------------
          selectedCustomerGroup_INC_ATTR: _.get(
            attr_inclusion,
            "Customer_Group",
            undefined
          ),
          selectedBusiness_INC_ATTR: _.get(
            attr_inclusion,
            "Business",
            undefined
          ),
          selectedArea_INC_ATTR: _.get(attr_inclusion, "Area", undefined),
          selectedShopDesc_INC_ATTR: _.get(
            attr_inclusion,
            "ShopDesc",
            undefined
          ),
          selectedCategory_INC_ATTR: _.get(
            attr_inclusion,
            "Category",
            undefined
          ),
          selectedMerchCat_INC_ATTR: _.get(
            attr_inclusion,
            "MerchCat",
            undefined
          ),

          selectedCharCollection_INC_ATTR: _.get(
            attr_inclusion,
            "CharCollection",
            undefined
          ),
          selectedVendor_INC_ATTR: _.get(attr_inclusion, "Vendor", undefined),
          selectedArticleStatus_INC_ATTR: _.get(
            attr_inclusion,
            "ArticleStatus",
            undefined
          ),
          selectedRppMinValue_INC_ATTR: _.get(attr_inclusion, "RppMin", null),
          selectedRppMaxValue_INC_ATTR: _.get(attr_inclusion, "RppMax", null),
          selectedRppSpfc_INC_ATTR: _.get(
            attr_inclusion,
            "Rpp_Spfc",
            undefined
          ),

          selectedAttrColor_INC_ATTR: _.get(
            attr_inclusion,
            "AttrColor",
            undefined
          ),
          selectedAttrFabric_INC_ATTR: _.get(
            attr_inclusion,
            "AttrFabric",
            undefined
          ),
          selectedAttrSize_INC_ATTR: _.get(
            attr_inclusion,
            "AttrSize",
            undefined
          ),
          selectedAttributeTheme_INC_ATTR: _.get(
            attr_inclusion,
            "AttrTheme",
            undefined
          ),
          selectedProductBrand_INC_ATTR: _.get(
            attr_inclusion,
            "ProductBrand",
            undefined
          ),
          // --------------------------------------------------
          selectedCustomerGroup_EXC_ATTR: _.get(
            attr_exclusion,
            "Customer_Group",
            undefined
          ),
          selectedBusiness_EXC_ATTR: _.get(
            attr_exclusion,
            "Business",
            undefined
          ),
          selectedArea_EXC_ATTR: _.get(attr_exclusion, "Area", undefined),
          selectedShopDesc_EXC_ATTR: _.get(
            attr_exclusion,
            "ShopDesc",
            undefined
          ),
          selectedCategory_EXC_ATTR: _.get(
            attr_exclusion,
            "Category",
            undefined
          ),
          selectedMerchCat_EXC_ATTR: _.get(
            attr_exclusion,
            "MerchCat",
            undefined
          ),

          selectedCharCollection_EXC_ATTR: _.get(
            attr_exclusion,
            "CharCollection",
            undefined
          ),
          selectedVendor_EXC_ATTR: _.get(attr_exclusion, "Vendor", undefined),
          selectedArticleStatus_EXC_ATTR: _.get(
            attr_exclusion,
            "ArticleStatus",
            undefined
          ),
          selectedRppMinValue_EXC_ATTR: _.get(attr_exclusion, "RppMin", null),
          selectedRppMaxValue_EXC_ATTR: _.get(attr_exclusion, "RppMax", null),
          selectedRppSpfc_EXC_ATTR: _.get(
            attr_exclusion,
            "Rpp_Spfc",
            undefined
          ),

          selectedAttrColor_EXC_ATTR: _.get(
            attr_exclusion,
            "AttrColor",
            undefined
          ),
          selectedAttrFabric_EXC_ATTR: _.get(
            attr_exclusion,
            "AttrFabric",
            undefined
          ),
          selectedAttrSize_EXC_ATTR: _.get(
            attr_exclusion,
            "AttrSize",
            undefined
          ),
          selectedAttributeTheme_EXC_ATTR: _.get(
            attr_exclusion,
            "AttrTheme",
            undefined
          ),
          selectedProductBrand_EXC_ATTR: _.get(
            attr_exclusion,
            "ProductBrand",
            undefined
          ),
          // --------------------------------------------------
          selectedCustomerGroup_INC_SKU: _.get(
            sku_inclusion,
            "Customer_Group",
            undefined
          ),
          selectedBusiness_INC_SKU: _.get(sku_inclusion, "Business", undefined),
          selectedArea_INC_SKU: _.get(sku_inclusion, "Area", undefined),
          selectedShopDesc_INC_SKU: _.get(sku_inclusion, "ShopDesc", undefined),
          selectedCategory_INC_SKU: _.get(sku_inclusion, "Category", undefined),
          selectedMerchCat_INC_SKU: _.get(sku_inclusion, "MerchCat", undefined),

          selectedCharCollection_INC_SKU: _.get(
            sku_inclusion,
            "CharCollection",
            undefined
          ),
          selectedVendor_INC_SKU: _.get(sku_inclusion, "Vendor", undefined),
          selectedArticleStatus_INC_SKU: _.get(
            sku_inclusion,
            "ArticleStatus",
            undefined
          ),
          selectedRppMinValue_INC_SKU: _.get(sku_inclusion, "RppMin", null),
          selectedRppMaxValue_INC_SKU: _.get(sku_inclusion, "RppMax", null),

          selectedAttrColor_INC_SKU: _.get(
            sku_inclusion,
            "AttrColor",
            undefined
          ),
          selectedAttrFabric_INC_SKU: _.get(
            sku_inclusion,
            "AttrFabric",
            undefined
          ),
          selectedAttrSize_INC_SKU: _.get(sku_inclusion, "AttrSize", undefined),
          selectedAttributeTheme_INC_SKU: _.get(
            sku_inclusion,
            "AttrTheme",
            undefined
          ),
          selectedProductBrand_INC_SKU: _.get(
            sku_inclusion,
            "ProductBrand",
            undefined
          ),

          selectedArticlesIds_INC_SKU: _.get(
            sku_inclusion,
            "ArticleIds",
            undefined
          ),
          // --------------------------------------------------
          selectedCustomerGroup_EXC_SKU: _.get(
            sku_exclusion,
            "Customer_Group",
            undefined
          ),
          selectedBusiness_EXC_SKU: _.get(sku_exclusion, "Business", undefined),
          selectedArea_EXC_SKU: _.get(sku_exclusion, "Area", undefined),
          selectedShopDesc_EXC_SKU: _.get(sku_exclusion, "ShopDesc", undefined),
          selectedCategory_EXC_SKU: _.get(sku_exclusion, "Category", undefined),
          selectedMerchCat_EXC_SKU: _.get(sku_exclusion, "MerchCat", undefined),

          selectedCharCollection_EXC_SKU: _.get(
            sku_exclusion,
            "CharCollection",
            undefined
          ),
          selectedVendor_EXC_SKU: _.get(sku_exclusion, "Vendor", undefined),
          selectedArticleStatus_EXC_SKU: _.get(
            sku_exclusion,
            "ArticleStatus",
            undefined
          ),
          selectedRppMinValue_EXC_SKU: _.get(sku_exclusion, "RppMin", null),
          selectedRppMaxValue_EXC_SKU: _.get(sku_exclusion, "RppMax", null),

          selectedAttrColor_EXC_SKU: _.get(
            sku_exclusion,
            "AttrColor",
            undefined
          ),
          selectedAttrFabric_EXC_SKU: _.get(
            sku_exclusion,
            "AttrFabric",
            undefined
          ),
          selectedAttrSize_EXC_SKU: _.get(sku_exclusion, "AttrSize", undefined),
          selectedAttributeTheme_EXC_SKU: _.get(
            sku_exclusion,
            "AttrTheme",
            undefined
          ),
          selectedProductBrand_EXC_SKU: _.get(
            sku_exclusion,
            "ProductBrand",
            undefined
          ),

          selectedArticlesIds_EXC_SKU: _.get(
            sku_exclusion,
            "ArticleIds",
            undefined
          ),
          joann_cutover: nextProps.existingAdgroupData.updated_by_user === 'joann_cutover' 
        },
        () => {
          this.handleSelectionChange_INC_SKU(
            _.get(sku_inclusion, "MerchCat", undefined),
            "selectedMerchCat_INC_SKU"
          );
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const pageType = _.get(this.props, "pageType", "create");
  if (_.eq(pageType, "edit") && prevState.customerGroupOptions !== this.state.customerGroupOptions) {
    // Now fetch the new data here.
                this.handleIncludeExcludeHierachies()

  }

  if(this.props.getAllHierarchiesData != prevProps.getAllHierarchiesData){

      const get_all_hierarchies = this.props.getAllHierarchiesData
      const customerGroupOptions = _.map(get_all_hierarchies.customer_group, (row) => {
              return { value: String(row.customer_group_id), label: `${row.customer_group_id} - ${row.customer_group}` };
            })
      const businessOptions = _.map(get_all_hierarchies.business, (row) => {
              return { value: String(row.business_id), label: `${row.business_id} - ${row.business}` };
            })
      const shopOptions = _.map(get_all_hierarchies.shop, (row) => {
              return { value: String(row.shop_id), label: `${row.shop_id} - ${row.shop}` };
            })
      const categoryOptions = _.map(get_all_hierarchies.category, (row) => {
              return { value: String(row.category_id), label: `${row.category_id} - ${row.category}` };
            })

      const merchantCategoryOptions = _.map(get_all_hierarchies.merchant_category, (row) => {
              return { value: String(row.merchant_category_id), label: `${row.merchant_category_id} - ${row.merchant_category}` };
            })

            // const notAvaliableCustomerGroupOptions =  get_all_hierarchies.notAvailableMerchantCategory.map(obj=> obj.name)
            // const notAvaliableBusinessOptions =  get_all_hierarchies.notAvailableBusiness.map(obj=> obj.name)
            // const notAvaliableShopOptions =  get_all_hierarchies.notAvailableShop.map(obj=> obj.name)
            // const notAvaliableCategoryOptions =  get_all_hierarchies.notAvailableCategory.map(obj=> obj.name)
            // const notAvaliableMerchantCategoryOptions =  get_all_hierarchies.notAvailableMerchantCategory.map(obj=> obj.name)

            this.setState({
            availableCustomerGroupOptions_INC_ATTR: customerGroupOptions,
            availableBusinessOptions_INC_ATTR:       businessOptions,
            availableShopDescOptions_INC_ATTR:       shopOptions,
            availableCategoryOptions_INC_ATTR:       categoryOptions,
            availableMerchCatOptions_INC_ATTR:       merchantCategoryOptions,
            customerGroupOptions,
            businessOptions,
            shopOptions,
            categoryOptions,
            merchantCategoryOptions,
            // notAvaliableCustomerGroupOptions,
            // notAvaliableBusinessOptions,
            // notAvaliableShopOptions,
            // notAvaliableCategoryOptions,
            // notAvaliableMerchantCategoryOptions
          });
  }
    if(this.props.getAllAttributesData != prevProps.getAllAttributesData){
      const allAttributesList = this.props.getAllAttributesData
      const collectionOptions = _.map(allAttributesList.collection, (row) => {
              return { value: String(row), label: String(row) };
            })
      // const vendorOptions = _.map(allAttributesList.vendor, (row) => {
      //         return { value: String(row.vendor), label: `${row.vendor_code} - ${row.vendor_name}`, vendor_code: String(row.vendor_code) };
      //       })
      const vendorOptions = _.map(allAttributesList.vendor, (row) => {
        return { value: String(row.vendor_code), label: `${row.vendor_code} - ${row.vendor_name}`, vendor_id: String(row.vendor) };
      })
      const articleStatusOptions = _.map(allAttributesList.article_status, (row) => {
              return { value: String(row.article_status), label: `${String(row.article_status)} - ${String(row.article_status_desc)}` };
            })
      const productBrandOptions = _.map(allAttributesList.product_brand, (row) => {
              return { value: String(row), label: String(row) };
            })

      const attributeColorOptions = _.map(allAttributesList.attribute_color, (row) => {
              return { value: String(row), label: String(row) };
            })

            const attributeFabricOptions = _.map(allAttributesList.attribute_fabric, (row) => {
              return { value: String(row), label: String(row) };
            })
      const attributeSizeOptions = _.map(allAttributesList.attribute_size, (row) => {
              return { value: String(row), label: String(row) };
            })

      const attributeThemeOptions = _.map(allAttributesList.attribute_theme, (row) => {
              return { value: String(row), label: String(row) };
            })
      // let selectedVendor_inc = this.state.selectedVendor_INC_ATTR
      // let selectedVendor_exc = this.state.selectedVendor_EXC_ATTR
      //     if(_.eq(pageType, "edit")){
      //       if(this.state.selectedVendor_INC_ATTR && this.state.selectedVendor_INC_ATTR.length > 0){
      //         selectedVendor_inc = vendorOptions.filter(obj=> this.state.selectedVendor_INC_ATTR.indexOf(obj.vendor_code) !==-1).map(obj=> obj.value)
      //       }
      //       if(this.state.selectedVendor_EXC_ATTR && this.state.selectedVendor_EXC_ATTR.length > 0){
      //         selectedVendor_exc = vendorOptions.filter(obj=> this.state.selectedVendor_EXC_ATTR.indexOf(obj.vendor_code) !==-1).map(obj=> obj.value)
      //       }
      //     }

            this.setState({
            collectionOptions,
            vendorOptions,
            articleStatusOptions,
            productBrandOptions,
            attributeColorOptions,
            attributeFabricOptions,
            attributeSizeOptions,
            attributeThemeOptions,
            // selectedVendor_INC_ATTR: selectedVendor_inc,
            // selectedVendor_EXC_ATTR:selectedVendor_exc
          });
  }

}


  provideOptionsAndSelected = (
    ALL_OPTIONS,
    allowedOptions,
    selectedOptions
  ) => {
    let finalSelection = [];
    const finalOptions = _.map(ALL_OPTIONS, (ob) => {
      if (_.indexOf(allowedOptions, ob.value) >= 0) {
        // Select which is present

        // Update selected options also
        if (_.indexOf(selectedOptions, ob.value) >= 0) {
          // Select which are already present
          finalSelection.push(ob.value);
        }
        return ob;
      }
      return { ...ob, disabled: true };
    });

    return { finalOptions, finalSelection };
  };


  handleIncludeExcludeHierachies = async()=>{
    /*
    let customer_group_inc_data = (this.state.selectedCustomerGroup_INC_ATTR || []) ;
    let business_inc_data = (this.state.selectedBusiness_INC_ATTR || []) ;
    let shop_inc_data = (this.state.selectedShopDesc_INC_ATTR || [] ) ;
    let category_inc_data = (this.state.selectedCategory_INC_ATTR || [] ) ;
    let merchant_category_inc_data = (this.state.selectedMerchCat_INC_ATTR || [] )


    let notAvaliableBusinessOptions = this.state.notAvaliableBusinessOptions.filter(obj=> business_inc_data.indexOf(obj)!==-1 )
    let notAvaliableShopOptions = this.state.notAvaliableBusinessOptions.filter(obj=> shop_inc_data.indexOf(obj)!==-1 )
    let notAvaliableCategoryOptions = this.state.notAvaliableBusinessOptions.filter(obj=> category_inc_data.indexOf(obj)!==-1 )
    let notAvaliableMerchantCategoryOptions = this.state.notAvaliableBusinessOptions.filter(obj=> merchant_category_inc_data.indexOf(obj)!==-1 )
    */
    const finalObj = {
      include_hierarchy: {
        customer_group: (this.state.selectedCustomerGroup_INC_ATTR || []),
        business: (this.state.selectedBusiness_INC_ATTR || []),
        Area: (this.state.selectedArea_INC_ATTR || [] ),
        shop: (this.state.selectedShopDesc_INC_ATTR || [] ),
        category: (this.state.selectedCategory_INC_ATTR || [] ),
        merchant_category: (this.state.selectedMerchCat_INC_ATTR || [] ),
        // notAvaliableBusinessOptions,
        // notAvaliableShopOptions,
        // notAvaliableCategoryOptions,
        // notAvaliableMerchantCategoryOptions
      },
      exclude_hierarchy: { 
        customer_group: (this.state.selectedCustomerGroup_EXC_ATTR || []),
        business: (this.state.selectedBusiness_EXC_ATTR || [] ),
        Area: (this.state.selectedArea_EXC_ATTR || [] ),
        shop: (this.state.selectedShopDesc_EXC_ATTR || [] ),
        category: (this.state.selectedCategory_EXC_ATTR || [] ),
        merchant_category: (this.state.selectedMerchCat_EXC_ATTR || [] )
      },
      /*
      noDataHierarchy:{
            notAvaliableCustomerGroupOptions : this.state.notAvaliableCustomerGroupOptions,
            notAvaliableBusinessOptions : this.state.notAvaliableBusinessOptions,
            notAvaliableShopOptions : this.state.notAvaliableShopOptions,
            notAvaliableCategoryOptions : this.state.notAvaliableCategoryOptions,
            notAvaliableMerchantCategoryOptions: this.state.notAvaliableMerchantCategoryOptions
      }
      */
    };
      // this.props.togglePageLoading(true);
      const filterdData = await this.props.getMerchIdHierarchyMapping(finalObj)
      // this.props.togglePageLoading(false);

      // Include attributes 
      let selectedBusiness_INC = _.uniq((filterdData.include_attr[0].business || []).concat(this.state.selectedBusiness_INC_ATTR)).map(obj=> String(obj))
      let selectedShopDesc_INC = _.uniq((filterdData.include_attr[0].shop || []).concat(this.state.selectedShopDesc_INC_ATTR)).map(obj=> String(obj))
      let selectedCategory_INC = _.uniq((filterdData.include_attr[0].category || []).concat(this.state.selectedCategory_INC_ATTR)).map(obj=> String(obj))
      let merchantCategory_INC = _.uniq((filterdData.include_attr[0].merchant_category || []).concat(this.state.selectedMerchCat_INC_ATTR)).map(obj=> String(obj))
      /*
      if( notAvaliableBusinessOptions.length ===0 && notAvaliableShopOptions.length ===0 && notAvaliableCategoryOptions.length ===0 && notAvaliableMerchantCategoryOptions.length ===0 ){
        selectedBusiness_INC = selectedBusiness_INC.concat([...this.state.notAvaliableBusinessOptions])
        selectedShopDesc_INC = selectedShopDesc_INC.concat([...this.state.notAvaliableShopOptions])
        selectedCategory_INC = selectedCategory_INC.concat([...this.state.notAvaliableCategoryOptions])
        merchantCategory_INC = merchantCategory_INC.concat([...this.state.notAvaliableMerchantCategoryOptions])
      }else{
        selectedBusiness_INC = selectedBusiness_INC.concat([...filterdData.notAvailable[0].business])
        selectedShopDesc_INC = selectedShopDesc_INC.concat([...filterdData.notAvailable[0].shop])
        selectedCategory_INC = selectedCategory_INC.concat([...filterdData.notAvailable[0].category])
        merchantCategory_INC = merchantCategory_INC.concat([...filterdData.notAvailable[0].merchant_category])
      }
      */
       const business_inc_fields = this.provideOptionsAndSelected(
 				[...this.state.businessOptions],
 				selectedBusiness_INC,
         []
 			);
       const shop_inc_fields = this.provideOptionsAndSelected(
 				[...this.state.shopOptions],
 				selectedShopDesc_INC,
         []
 			);
       const category_inc_fields = this.provideOptionsAndSelected(
 				[...this.state.categoryOptions],
 				selectedCategory_INC,
         []
 			);
       const merchant_category_inc_fields = this.provideOptionsAndSelected(
 				[...this.state.merchantCategoryOptions],
 				merchantCategory_INC,
         []
 			);
       
      const availableBusinessOptions_INC_ATTR = _.get(business_inc_fields, "finalOptions");
 			const availableShopDescOptions_INC_ATTR = _.get(shop_inc_fields, "finalOptions");
 			const availableCategoryOptions_INC_ATTR = _.get(category_inc_fields, "finalOptions");
 			const availableMerchCatOptions_INC_ATTR = _.get(merchant_category_inc_fields, "finalOptions");


      //  Exclude Attributes
      const business_exc = filterdData.exclude_attr[0].business || []
      const shop_exc = filterdData.exclude_attr[0].shop || []
      const category_exc = filterdData.exclude_attr[0].category || []
      const merchant_category_exc = filterdData.exclude_attr[0].merchant_category || []

      const business_exc_fields = this.provideOptionsAndSelected(
 				[...this.state.businessOptions],
 				_.uniq(business_exc).concat(this.state.selectedBusiness_EXC_ATTR).map(obj=> String(obj)),
         []
 			);
       const shop_exc_fields = this.provideOptionsAndSelected(
 				[...this.state.shopOptions],
 				_.uniq(shop_exc).concat(this.state.selectedShopDesc_EXC_ATTR).map(obj=> String(obj)),
         []
 			);
       const category_exc_fields = this.provideOptionsAndSelected(
 				[...this.state.categoryOptions],
 				_.uniq(category_exc).concat(this.state.selectedCategory_EXC_ATTR).map(obj=> String(obj)),
         []
 			);
       const merchant_category_exc_fields = this.provideOptionsAndSelected(
 				[...this.state.merchantCategoryOptions],
 				_.uniq(merchant_category_exc).concat(this.state.selectedMerchCat_EXC_ATTR).map(obj=> String(obj)),
         []
 			);
      let availableCustomerGroupOptions_EXC_ATTR = []
      let availableBusinessOptions_EXC_ATTR = _.get(business_exc_fields, "finalOptions");
 			let availableShopDescOptions_EXC_ATTR = _.get(shop_exc_fields, "finalOptions");
 			let availableCategoryOptions_EXC_ATTR = _.get(category_exc_fields, "finalOptions");
 			let availableMerchCatOptions_EXC_ATTR = _.get(merchant_category_exc_fields, "finalOptions");

      if(business_exc.length ===0){
        availableBusinessOptions_EXC_ATTR = []
      }
      if(shop_exc.length ===0){
        availableShopDescOptions_EXC_ATTR = []
      }
      if(category_exc.length ===0){
        availableCategoryOptions_EXC_ATTR = []
      }
      if(merchant_category_exc.length ===0){
        availableMerchCatOptions_EXC_ATTR = []
      }
      
      this.setState({
        availableBusinessOptions_INC_ATTR,
        availableShopDescOptions_INC_ATTR,
        availableCategoryOptions_INC_ATTR,
        availableMerchCatOptions_INC_ATTR,

        availableCustomerGroupOptions_EXC_ATTR,
        availableBusinessOptions_EXC_ATTR,
        availableShopDescOptions_EXC_ATTR,
        availableCategoryOptions_EXC_ATTR,
        availableMerchCatOptions_EXC_ATTR
      })
  }
  handleSelectionChange_INC_ATTR = async(value, columnName,type) => {
    if (_.isNil(columnName)) {
      return null;
    }

    const hierarchyAttributesOrder = [
      "selectedCustomerGroup_INC_ATTR",
      "selectedBusiness_INC_ATTR",
      "selectedShopDesc_INC_ATTR",
      "selectedCategory_INC_ATTR",
      "selectedMerchCat_INC_ATTR",
    ];
    let isCurrentHierarchy = false;
    let finalObj = {}
    hierarchyAttributesOrder.forEach(obj=>{
        if(obj === columnName){
          isCurrentHierarchy = true;
        }
        if(isCurrentHierarchy){
          finalObj[`${obj}`] =[]
        }
    })
    this.setState({ ...finalObj,[columnName]: value }, async() => {
      if(hierarchyAttributesOrder.indexOf(columnName)!==-1){
        this.handleIncludeExcludeHierachies()
      }
    })
    

  };
  handleSelectionChange_EXC_ATTR = async(value, columnName,type) => {
    if (_.isNil(columnName)) {
      return null;
    }

    const hierarchyAttributesOrder = [
      "selectedCustomerGroup_EXC_ATTR",
      "selectedBusiness_EXC_ATTR",
      "selectedShopDesc_EXC_ATTR",
      "selectedCategory_EXC_ATTR",
      "selectedMerchCat_EXC_ATTR",
    ];
    let isCurrentHierarchy = false;
    let finalObj = {}
    hierarchyAttributesOrder.forEach(obj=>{
        if(obj === columnName){
          isCurrentHierarchy = true;
        }
        if(isCurrentHierarchy){
          finalObj[`${obj}`] =[]
        }
    })
    this.setState({ ...finalObj,[columnName]: value }, async() => {
      if(hierarchyAttributesOrder.indexOf(columnName)!==-1){
        this.handleIncludeExcludeHierachies()
      }
    })
    

  };

  handleSelectionChange_INC_SKU = (value, columnName) => {
    if (_.isNil(columnName)) {
      return null;
    }
    console.log("======",columnName,value)
    this.setState({  [columnName]: value });
  };

  handleSelectionChange_EXC_SKU = (value, columnName) => {
    if (_.isNil(columnName)) {
      return null;
    }
    this.setState({ [columnName]: value });
  };

  getRequestObjectForAttributes = (type) => {
    console.log("Type Requires",type)
    const {
      selectedCustomerGroup_INC_ATTR,
      selectedBusiness_INC_ATTR,
      selectedArea_INC_ATTR,
      selectedShopDesc_INC_ATTR,
      selectedCategory_INC_ATTR,
      selectedMerchCat_INC_ATTR,

      selectedCharCollection_INC_ATTR,
      selectedVendor_INC_ATTR,
      selectedArticleStatus_INC_ATTR,
      selectedRppMinValue_INC_ATTR,
      selectedRppMaxValue_INC_ATTR,
      selectedRppSpfc_INC_ATTR,

      selectedAttrColor_INC_ATTR,
      selectedAttrFabric_INC_ATTR,
      selectedAttrSize_INC_ATTR,
      selectedAttributeTheme_INC_ATTR,
      selectedProductBrand_INC_ATTR,
      // -----------------------------------------------------------
      selectedCustomerGroup_EXC_ATTR,
      selectedBusiness_EXC_ATTR,
      selectedArea_EXC_ATTR,
      selectedShopDesc_EXC_ATTR,
      selectedCategory_EXC_ATTR,
      selectedMerchCat_EXC_ATTR,

      selectedCharCollection_EXC_ATTR,
      selectedVendor_EXC_ATTR,
      selectedArticleStatus_EXC_ATTR,
      selectedRppMinValue_EXC_ATTR,
      selectedRppMaxValue_EXC_ATTR,
      selectedRppSpfc_EXC_ATTR,

      selectedAttrColor_EXC_ATTR,
      selectedAttrFabric_EXC_ATTR,
      selectedAttrSize_EXC_ATTR,
      selectedAttributeTheme_EXC_ATTR,
      selectedProductBrand_EXC_ATTR,
      // -----------------------------------------------------------
      selectedCustomerGroup_INC_SKU,
      selectedBusiness_INC_SKU,
      selectedArea_INC_SKU,
      selectedShopDesc_INC_SKU,
      selectedCategory_INC_SKU,
      selectedMerchCat_INC_SKU,

      selectedCharCollection_INC_SKU,
      selectedVendor_INC_SKU,
      selectedArticleStatus_INC_SKU,
      selectedRppMinValue_INC_SKU,
      selectedRppMaxValue_INC_SKU,
      selectedAttrColor_INC_SKU,
      selectedAttrFabric_INC_SKU,
      selectedAttrSize_INC_SKU,
      selectedAttributeTheme_INC_SKU,
      selectedProductBrand_INC_SKU,

      selectedArticlesIds_INC_SKU,
      // -----------------------------------------------------------
      selectedCustomerGroup_EXC_SKU,
      selectedBusiness_EXC_SKU,
      selectedArea_EXC_SKU,
      selectedShopDesc_EXC_SKU,
      selectedCategory_EXC_SKU,
      selectedMerchCat_EXC_SKU,

      selectedCharCollection_EXC_SKU,
      selectedVendor_EXC_SKU,
      selectedArticleStatus_EXC_SKU,
      selectedRppMinValue_EXC_SKU,
      selectedRppMaxValue_EXC_SKU,

      selectedAttrColor_EXC_SKU,
      selectedAttrFabric_EXC_SKU,
      selectedAttrSize_EXC_SKU,
      selectedAttributeTheme_EXC_SKU,
      selectedProductBrand_EXC_SKU,

      selectedArticlesIds_EXC_SKU,
      // -----------------------------------------------------------

      selectedSKU_INC,
      selectedSKU_EXC,
      selectedRppSpfc_INC_SKU,
      selectedRppSpfc_EXC_SKU
    } = this.state;
    const pageType = _.get(this.props, "pageType", "create");
    const adgroupName = _.get(this.props, "adgroupName", null);
    let vendor_inc_attr_ids = []
    let vendor_exc_attr_ids = []
    let vendor_inc_sku = []
    let vendor_exc_sku = []
      if(this.state.selectedVendor_INC_ATTR && this.state.selectedVendor_INC_ATTR.length > 0){
        vendor_inc_attr_ids = this.state.vendorOptions.filter(obj=> selectedVendor_INC_ATTR.indexOf(obj.value) !==-1).map(obj=> obj.vendor_id)
      }
      if(this.state.selectedVendor_EXC_ATTR && this.state.selectedVendor_EXC_ATTR.length > 0){
        vendor_exc_attr_ids = this.state.vendorOptions.filter(obj=> selectedVendor_EXC_ATTR.indexOf(obj.value) !==-1).map(obj=> obj.vendor_id)
      }

      if(this.state.selectedVendor_INC_SKU && this.state.selectedVendor_INC_SKU.length > 0){
        vendor_inc_sku = this.state.vendorOptions.filter(obj=> selectedVendor_INC_SKU.indexOf(obj.value) !==-1).map(obj=> obj.vendor_id)
      }
      if(this.state.selectedVendor_EXC_SKU && this.state.selectedVendor_EXC_SKU.length > 0){
        vendor_exc_sku = this.state.vendorOptions.filter(obj=> selectedVendor_EXC_SKU.indexOf(obj.value) !==-1).map(obj=> obj.vendor_id)
      }

    const finalObj = {
      attr_inclusion: {
        Customer_Group: selectedCustomerGroup_INC_ATTR,
        Business: selectedBusiness_INC_ATTR,
        Area: selectedArea_INC_ATTR,
        ShopDesc: selectedShopDesc_INC_ATTR,
        Category: selectedCategory_INC_ATTR,
        MerchCat: selectedMerchCat_INC_ATTR,
        CharCollection: selectedCharCollection_INC_ATTR,
        Vendor_Ids: vendor_inc_attr_ids,
        Vendor: selectedVendor_INC_ATTR,
        ArticleStatus: selectedArticleStatus_INC_ATTR,
        RppMin: selectedRppMinValue_INC_ATTR,
        RppMax: selectedRppMaxValue_INC_ATTR,
        Rpp_Spfc: selectedRppSpfc_INC_ATTR,
        AttrColor: selectedAttrColor_INC_ATTR,
        AttrFabric: selectedAttrFabric_INC_ATTR,
        AttrSize: selectedAttrSize_INC_ATTR,
        AttrTheme: selectedAttributeTheme_INC_ATTR,
        ProductBrand: selectedProductBrand_INC_ATTR,
      },
      attr_exclusion: {
        Customer_Group: selectedCustomerGroup_EXC_ATTR,
        Business: selectedBusiness_EXC_ATTR,
        Area: selectedArea_EXC_ATTR,
        ShopDesc: selectedShopDesc_EXC_ATTR,
        Category: selectedCategory_EXC_ATTR,
        MerchCat: selectedMerchCat_EXC_ATTR,
        CharCollection: selectedCharCollection_EXC_ATTR,
        Vendor_Ids: vendor_exc_attr_ids,
        Vendor: selectedVendor_EXC_ATTR,
        ArticleStatus: selectedArticleStatus_EXC_ATTR,
        RppMin: selectedRppMinValue_EXC_ATTR,
        RppMax: selectedRppMaxValue_EXC_ATTR,
        Rpp_Spfc: selectedRppSpfc_EXC_ATTR,
        AttrColor: selectedAttrColor_EXC_ATTR,
        AttrFabric: selectedAttrFabric_EXC_ATTR,
        AttrSize: selectedAttrSize_EXC_ATTR,
        AttrTheme: selectedAttributeTheme_EXC_ATTR,
        ProductBrand: selectedProductBrand_EXC_ATTR,
      },
      sku_inclusion: {
        Customer_Group: selectedCustomerGroup_INC_SKU,
        Business: selectedBusiness_INC_SKU,
        Area: selectedArea_INC_SKU,
        ShopDesc: selectedShopDesc_INC_SKU,
        Category: selectedCategory_INC_SKU,
        MerchCat: selectedMerchCat_INC_SKU,
        CharCollection: selectedCharCollection_INC_SKU,
        Vendor: selectedVendor_INC_SKU,
        Vendor_Ids: vendor_inc_sku,
        ArticleStatus: selectedArticleStatus_INC_SKU,
        RppMin: selectedRppMinValue_INC_SKU,
        RppMax: selectedRppMaxValue_INC_SKU,
        Rpp_Spfc: selectedRppSpfc_INC_SKU,
        AttrColor: selectedAttrColor_INC_SKU,
        AttrFabric: selectedAttrFabric_INC_SKU,
        AttrSize: selectedAttrSize_INC_SKU,
        AttrTheme: selectedAttributeTheme_INC_SKU,
        ProductBrand: selectedProductBrand_INC_SKU,

        ArticleIds: selectedArticlesIds_INC_SKU,
      },
      sku_exclusion: {
        Customer_Group: selectedCustomerGroup_EXC_SKU,
        Business: selectedBusiness_EXC_SKU,
        Area: selectedArea_EXC_SKU,
        ShopDesc: selectedShopDesc_EXC_SKU,
        Category: selectedCategory_EXC_SKU,
        MerchCat: selectedMerchCat_EXC_SKU,
        CharCollection: selectedCharCollection_EXC_SKU,
        Vendor: selectedVendor_EXC_SKU,
        Vendor_Ids: vendor_exc_sku,
        ArticleStatus: selectedArticleStatus_EXC_SKU,
        RppMin: selectedRppMinValue_EXC_SKU,
        RppMax: selectedRppMaxValue_EXC_SKU,
        Rpp_Spfc: selectedRppSpfc_EXC_SKU,
        AttrColor: selectedAttrColor_EXC_SKU,
        AttrFabric: selectedAttrFabric_EXC_SKU,
        AttrSize: selectedAttrSize_EXC_SKU,
        AttrTheme: selectedAttributeTheme_EXC_SKU,
        ProductBrand: selectedProductBrand_EXC_SKU,

        ArticleIds: selectedArticlesIds_EXC_SKU,
      },
      // sku_inc: ( type === 'Modal' ? selectedSKU_INC.map(d=> Number(d).toString()) : selectedSKU_INC ),
      // sku_exc: ( type === 'Modal' ? selectedSKU_EXC.map(d=> Number(d).toString()) : selectedSKU_EXC  ),
      sku_inc: selectedSKU_INC,
      sku_exc: selectedSKU_EXC,
      pageType,
      adgroupName,
    };
    return finalObj;
  };

  handleSaveBtn = () => {
    this.props.togglePageLoading(true);
      console.log("props console",this.props);
      const adgroupName = _.get(this.props, 'adgroupName', null);
      const adgroupDescription = _.get(this.props, 'adgroupDescription', null);
      const adgroupType = _.get(this.props, 'selectedAdgroupType', null);
      const merchId = _.get(this.props, 'merchId', null);
      const signDescription = _.get(this.props, 'signDescription', null);
      const label = _.get(this.props, 'label', null);
      const uom = _.get(this.props, 'uom', null);
      const subline = _.get(this.props, 'subline', null);
      const gmm = _.get(this.props, 'gmm', null);
      const merchantBuyerName = _.get(this.props, 'merchantBuyerName', null);
      const reqObjectForAttributes = this.getRequestObjectForAttributes('Save');
    if (reqObjectForAttributes.sku_inc.length === 1 && reqObjectForAttributes.sku_inc[0] === '00001172') { // 00001172 -> Dummy article , we are skipping the API call of validate Adgroup while creating and editing that adgroup with dummy article id
      const page = _.get(this.props , 'pageType' , null)
      console.log(page)
      if(page == 'create') {  //if we are creating a new Adgroup with 00001172 SKU ID
        this.props.createAdgroupV2({
          adgroupName , 
          adgroupDescription ,
          adgroupType , 
          merchId , 
          signDescription ,
          label , 
          uom , 
          subline , 
          gmm ,
          merchantBuyerName ,
          uiSelections : reqObjectForAttributes
        })
        .then((response)=> {
          if(response.status === false){
            this.setState({
              isFailure: true,
              failureMessage: response.msg
            });
          }
          if(response.status === true){
            this.setState({
              isSuccess: true,
              successMessage: "Adgroup created successfully"
            });
          }
          console.log("response",response.status,response.status===false);
          this.props.togglePageLoading(false)
          this.setState({adgroupSaved : true})
          setTimeout(()=> {
            this.props.history.push('/adgroupMaster')
            this.setState({
              isFailure: false,
              failureMessage: ""
            });
            this.setState({
              isSuccess: false,
              successMessage: ""
            });
          },5000)
        })
        .catch((error)=> {
          this.props.togglePageLoading(false)
        })
      } else if(page =='edit') { //if we are editing the adgroup with 00001172 SKY ID
        const existingAdgroupData = _.get(this.props, 'existingAdgroupData', null);
        const adgroupId = _.get(existingAdgroupData, 'id', null);
  
        // console.log('handleModalOk() existingAdgroupData:', existingAdgroupData);
        // console.log('handleModalOk() adgroupId:', adgroupId);
        this.props.updateAdgroupV2({
          adgroupId,
          adgroupName,
          adgroupDescription,
          adgroupType,
          merchId,
          signDescription,
          label,
          uom,
          subline,
          gmm,
          merchantBuyerName,
          uiSelections: reqObjectForAttributes
        })
        .then((response) => {
          if(response.status === false){
            this.setState({
              isFailure: true,
              failureMessage: response.msg
            });
          }
          if(response.status === true){
            this.setState({
              isSuccess: true,
              successMessage: "Adgroup Updated Successfully"
            });
          }
          console.log("🚀 ~ SkuSelectionMaster ~ .then ~ response:", response)
          // const data = _.get(response, 'data', []);
          // const status = _.get(response, 'status', false);
          // console.log('createAdgroupV2 response data:', data);
          // console.log('createAdgroupV2 response status:', status);
          // this.props.togglePageLoading(false);
          this.props.togglePageLoading(false);
  
          this.setState({ adgroupSaved: true })
          setTimeout(() => {
            this.props.history.push("/adgroupMaster")
            this.setState({
              isFailure: false,
              failureMessage: ""
            });
            this.setState({
              isSuccess: false,
              successMessage: ""
            });
          }, 5000);
    
        })
        .catch(() => {
          this.props.togglePageLoading(false);
  
        });
  
      }
    } else {
    this.props
      .validateAdgroup(reqObjectForAttributes)
      .then((response) => {
        const data = _.get(response, "data", []);
        const msg = _.get(response, "msg", "");
        const reqStatus = _.get(response, "status", false);

        const attr_inc_product_ids = _.get(data, "attr_inc_product_ids", []);
        const attr_exc_product_ids = _.get(data, "attr_exc_product_ids", []);
        const sku_inc = _.get(data, "sku_inc", []);
        const sku_exc = _.get(data, "sku_exc", []);

        const count_attr_inc = _.size(attr_inc_product_ids);
        const count_attr_exc = _.size(attr_exc_product_ids);
        const count_sku_inc = _.size(sku_inc);
        const count_sku_exc = _.size(sku_exc);

        const considered_product_ids = _.union(attr_inc_product_ids, sku_inc);
        const not_considered_product_ids = _.union(
          attr_exc_product_ids,
          sku_exc
        );
        let finalConsideredIds = considered_product_ids;

        const attr_inc_product_ids_keys = _.keyBy(
          attr_inc_product_ids,
          (o) => o
        );
        const attr_exc_product_ids_keys = _.keyBy(
          attr_exc_product_ids,
          (o) => o
        );

        const considered_product_ids_keys = _.keyBy(
          considered_product_ids,
          (o) => o
        );
        const not_considered_product_ids_keys = _.keyBy(
          not_considered_product_ids,
          (o) => o
        );

        if (_.size(not_considered_product_ids) > 0) {
          finalConsideredIds = _.filter(considered_product_ids, (r) => {
            const x = (not_considered_product_ids_keys[r] || not_considered_product_ids_keys[`0${r}`]);
            if (_.isEmpty(x)) {
              return true;
            }
            // if (_.indexOf(not_considered_product_ids, r) <0) { // Select which are already present
            //   return true;
            // }
          });
        }
        const totalConsideredArticles = _.size(finalConsideredIds);

        // Check for exclusion sku not present in inclusion
        const exclusion_sku_not_in_inclusion = [];
        if (_.size(not_considered_product_ids) > 0) {
          _.mapKeys(not_considered_product_ids_keys, (value, key) => {
            const exclusion_found = (considered_product_ids_keys[key] || considered_product_ids_keys[`0${key}`] );
            if (_.isEmpty(exclusion_found)) {
              exclusion_sku_not_in_inclusion.push(key);
            }
          });
        }

        // Duplicate sku included from Include SKU Panel
        const duplicate_sku_included_from_includesku = [];
        if (count_sku_inc > 0) {
          _.map(sku_inc, (key) => {
            const found = (attr_inc_product_ids_keys[key] || attr_inc_product_ids_keys[`0${key}`] );
            if (!_.isEmpty(found)) {
              duplicate_sku_included_from_includesku.push(key);
            }
          });
        }

        // Duplicate sku included from Include SKU Panel
        const duplicate_sku_excluded_from_excludesku = [];
        if (count_sku_exc > 0) {
          _.map(sku_exc, (key) => {
            const found = (attr_exc_product_ids_keys[key] || attr_exc_product_ids_keys[`0${key}`]);
            if (!_.isEmpty(found)) {
              duplicate_sku_excluded_from_excludesku.push(key);
            }
          });
        }

        // const count_attr_inc = _.get(data, 'count_attr_inc', 0);
        // const count_attr_exc = _.get(data, 'count_attr_exc', 0);
        // const count_sku_inc = _.get(data, 'count_sku_inc', 0);
        // const count_sku_exc = _.get(data, 'count_sku_exc', 0);
        // const totalConsideredArticles = _.get(data, 'totalConsideredArticles', 0);
        this.props.togglePageLoading(false);
        this.setState({
          modalOpen: true,
          adgroupValidationObj: {
            msg,
            reqStatus,
            count_attr_inc,
            count_attr_exc,
            count_sku_inc,
            count_sku_exc,
            totalConsideredArticles,
            exclusion_sku_not_in_inclusion,
            duplicate_sku_included_from_includesku,
            duplicate_sku_excluded_from_excludesku,
          },
        });
      })
      .catch(() => {
        this.props.togglePageLoading(false);
        this.setState({ modalOpen: false });
      });
    }
  };

  handleModalOk = () => {
    this.props.togglePageLoading(true);

    const pageType = _.get(this.props, "pageType", "create");
    const adgroupName = _.get(this.props, 'adgroupName', null);
    const adgroupDescription = _.get(this.props, 'adgroupDescription', null);
    const adgroupType = _.get(this.props, 'selectedAdgroupType', null);
    const merchId = _.get(this.props, 'merchId', null);
    const signDescription = _.get(this.props, 'signDescription', null);
    const label = _.get(this.props, 'label', null);
    const uom = _.get(this.props, 'uom', null);
    const subline = _.get(this.props, 'subline', null);
    const gmm = _.get(this.props, 'gmm', null);
    const merchantBuyerName = _.get(this.props, 'merchantBuyerName', null);

    const reqObjectForAttributes = this.getRequestObjectForAttributes('Modal');

    if (_.eq(pageType, 'edit')) {
      const existingAdgroupData = _.get(this.props, 'existingAdgroupData', null);
      const adgroupId = _.get(existingAdgroupData, 'id', null);

      // console.log('handleModalOk() existingAdgroupData:', existingAdgroupData);
      // console.log('handleModalOk() adgroupId:', adgroupId);
      this.props.updateAdgroupV2({
        adgroupId,
        adgroupName,
        adgroupDescription,
        adgroupType,
        merchId,
        signDescription,
        label,
        uom,
        subline,
        gmm,
        merchantBuyerName,
        uiSelections: reqObjectForAttributes
      })
      .then((response) => {
        // const data = _.get(response, 'data', []);
        // const status = _.get(response, 'status', false);
        // console.log('createAdgroupV2 response data:', data);
        // console.log('createAdgroupV2 response status:', status);
        // this.props.togglePageLoading(false);
        this.props.togglePageLoading(false);

        this.setState({ adgroupSaved: true })
        setTimeout(() => {
          this.props.history.push("/adgroupMaster")
        }, 2000);
  
      })
      .catch(() => {
        this.props.togglePageLoading(false);

      });

    } else if(_.eq(pageType, 'create')) {
      this.props.createAdgroupV2({
        adgroupName,
        adgroupDescription,
        adgroupType,
        merchId,
        signDescription,
        label,
        uom,
        subline,
        gmm,
        merchantBuyerName,
        uiSelections: reqObjectForAttributes
      })
      .then((response) => {
        // const data = _.get(response, 'data', []);
        // const status = _.get(response, 'status', false);
        // console.log('createAdgroupV2 response data:', data);
        // console.log('createAdgroupV2 response status:', status);
        // this.props.togglePageLoading(false);

        this.props.togglePageLoading(false);

        this.setState({ adgroupSaved: true })
        setTimeout(() => {
          this.props.history.push("/adgroupMaster")
        }, 2000);
  
  
      })
      .catch(() => {
        this.props.togglePageLoading(false);

      });
    }
  };

  handleModalCancel = () => {
    this.setState({ modalOpen: false });
  };

  setSelectedSKUS_INC = (selectedRowKeys) => {
    this.setState({ selectedSKU_INC: selectedRowKeys });
  };

  setSelectedSKUS_EXC = (selectedRowKeys) => {
    this.setState({ selectedSKU_EXC: selectedRowKeys });
  };

  handleShowSkuModal = (btnName) => {
    const { adgroupValidationObj } = this.state;
    let skuIdList = [];
    this.setState({ showViewSkuModal: true, skuModalLoading: true });
    if (_.eq(btnName, "exclusionsNotInInclusions")) {
      skuIdList = _.get(
        adgroupValidationObj,
        "exclusion_sku_not_in_inclusion",
        []
      );
    } else if (_.eq(btnName, "duplicateInclusions")) {
      skuIdList = _.get(
        adgroupValidationObj,
        "duplicate_sku_included_from_includesku",
        []
      );
    } else if (_.eq(btnName, "duplicateExclusions")) {
      skuIdList = _.get(
        adgroupValidationObj,
        "duplicate_sku_excluded_from_excludesku",
        []
      );
    }

    this.props
      .getSkuListFromSkuIds({
        skuIdList,
      })
      .then((response) => {
        const data = _.get(response, "data", []);
        this.setState({ skuModalLoading: false, SKUTableData: data });
      })
      .catch(() => {
        this.setState({ skuModalLoading: false, SKUTableData: [] });
      });
  };

  render() {
    const {
      articleStatusOptions,
      collectionOptions,
      modalOpen,
      adgroupValidationObj,
      adgroupSaved,
      selectedSKU_INC,
      selectedSKU_EXC,
      showViewSkuModal,
      skuModalLoading,
      SKUTableData,
    } = this.state;
    const {
      selectedCustomerGroup_INC_ATTR,
      selectedBusiness_INC_ATTR,
      selectedArea_INC_ATTR,
      selectedShopDesc_INC_ATTR,
      selectedCategory_INC_ATTR,
      selectedMerchCat_INC_ATTR,

      selectedCharCollection_INC_ATTR,
      selectedVendor_INC_ATTR,
      selectedArticleStatus_INC_ATTR,
      selectedRppMinValue_INC_ATTR,
      selectedRppMaxValue_INC_ATTR,
      selectedRppSpfc_INC_ATTR,

      selectedAttrColor_INC_ATTR,
      selectedAttrFabric_INC_ATTR,
      selectedAttrSize_INC_ATTR,
      selectedAttributeTheme_INC_ATTR,
      selectedProductBrand_INC_ATTR,

      availableCustomerGroupOptions_INC_ATTR,
      availableBusinessOptions_INC_ATTR,
      availableAreaOptions_INC_ATTR,
      availableShopDescOptions_INC_ATTR,
      availableCategoryOptions_INC_ATTR,
      availableMerchCatOptions_INC_ATTR,
      // -----------------------------------------------------------
      selectedCustomerGroup_EXC_ATTR,
      selectedBusiness_EXC_ATTR,
      selectedArea_EXC_ATTR,
      selectedShopDesc_EXC_ATTR,
      selectedCategory_EXC_ATTR,
      selectedMerchCat_EXC_ATTR,

      selectedCharCollection_EXC_ATTR,
      selectedVendor_EXC_ATTR,
      selectedArticleStatus_EXC_ATTR,
      selectedRppMinValue_EXC_ATTR,
      selectedRppMaxValue_EXC_ATTR,
      selectedRppSpfc_EXC_ATTR,

      selectedAttrColor_EXC_ATTR,
      selectedAttrFabric_EXC_ATTR,
      selectedAttrSize_EXC_ATTR,
      selectedAttributeTheme_EXC_ATTR,
      selectedProductBrand_EXC_ATTR,

      availableCustomerGroupOptions_EXC_ATTR,
      availableBusinessOptions_EXC_ATTR,
      availableAreaOptions_EXC_ATTR,
      availableShopDescOptions_EXC_ATTR,
      availableCategoryOptions_EXC_ATTR,
      availableMerchCatOptions_EXC_ATTR,
      // -----------------------------------------------------------
      selectedCustomerGroup_INC_SKU,
      selectedBusiness_INC_SKU,
      selectedArea_INC_SKU,
      selectedShopDesc_INC_SKU,
      selectedCategory_INC_SKU,
      selectedMerchCat_INC_SKU,

      selectedCharCollection_INC_SKU,
      selectedVendor_INC_SKU,
      selectedArticleStatus_INC_SKU,
      selectedRppMinValue_INC_SKU,
      selectedRppMaxValue_INC_SKU,
      selectedRppSpfc_INC_SKU,
      selectedAttrColor_INC_SKU,
      selectedAttrFabric_INC_SKU,
      selectedAttrSize_INC_SKU,
      selectedAttributeTheme_INC_SKU,
      selectedProductBrand_INC_SKU,

      selectedArticlesIds_INC_SKU,

      availableCustomerGroupOptions_INC_SKU,
      availableBusinessOptions_INC_SKU,
      availableAreaOptions_INC_SKU,
      availableShopDescOptions_INC_SKU,
      availableCategoryOptions_INC_SKU,
      availableMerchCatOptions_INC_SKU,
      // -----------------------------------------------------------
      selectedCustomerGroup_EXC_SKU,
      selectedBusiness_EXC_SKU,
      selectedArea_EXC_SKU,
      selectedShopDesc_EXC_SKU,
      selectedCategory_EXC_SKU,
      selectedMerchCat_EXC_SKU,

      selectedCharCollection_EXC_SKU,
      selectedVendor_EXC_SKU,
      selectedArticleStatus_EXC_SKU,
      selectedRppMinValue_EXC_SKU,
      selectedRppMaxValue_EXC_SKU,
      selectedRppSpfc_EXC_SKU,
      selectedAttrColor_EXC_SKU,
      selectedAttrFabric_EXC_SKU,
      selectedAttrSize_EXC_SKU,
      selectedAttributeTheme_EXC_SKU,
      selectedProductBrand_EXC_SKU,

      selectedArticlesIds_EXC_SKU,

      availableCustomerGroupOptions_EXC_SKU,
      availableBusinessOptions_EXC_SKU,
      availableAreaOptions_EXC_SKU,
      availableShopDescOptions_EXC_SKU,
      availableCategoryOptions_EXC_SKU,
      availableMerchCatOptions_EXC_SKU,
      // collectionOptions,
            vendorOptions,
            // articleStatusOptions,
            productBrandOptions,
            attributeColorOptions,
            attributeFabricOptions,
            attributeSizeOptions,
            attributeThemeOptions
    } = this.state;

    const { pageLoading } = this.props;
    const pageType = _.get(this.props, "pageType", "create");

    const stateObject_INC_ATTR = {
      selectedCustomerGroup_INC_ATTR,
      selectedBusiness_INC_ATTR,
      selectedArea_INC_ATTR,
      selectedShopDesc_INC_ATTR,
      selectedCategory_INC_ATTR,
      selectedMerchCat_INC_ATTR,

      selectedCharCollection_INC_ATTR,
      selectedVendor_INC_ATTR,
      selectedArticleStatus_INC_ATTR,
      selectedRppMinValue_INC_ATTR,
      selectedRppMaxValue_INC_ATTR,
      selectedRppSpfc_INC_ATTR,

      selectedAttrColor_INC_ATTR,
      selectedAttrFabric_INC_ATTR,
      selectedAttrSize_INC_ATTR,
      selectedAttributeTheme_INC_ATTR,
      selectedProductBrand_INC_ATTR,

      availableCustomerGroupOptions_INC_ATTR,
      availableBusinessOptions_INC_ATTR,
      availableAreaOptions_INC_ATTR,
      availableShopDescOptions_INC_ATTR,
      availableCategoryOptions_INC_ATTR,
      availableMerchCatOptions_INC_ATTR,
      collectionOptions,
            vendorOptions,
            articleStatusOptions,
            productBrandOptions,
            attributeColorOptions,
            attributeFabricOptions,
            attributeSizeOptions,
            attributeThemeOptions
    };

    const stateObject_EXC_ATTR = {
      selectedCustomerGroup_EXC_ATTR,
      selectedBusiness_EXC_ATTR,
      selectedArea_EXC_ATTR,
      selectedShopDesc_EXC_ATTR,
      selectedCategory_EXC_ATTR,
      selectedMerchCat_EXC_ATTR,

      selectedCharCollection_EXC_ATTR,
      selectedVendor_EXC_ATTR,
      selectedArticleStatus_EXC_ATTR,
      selectedRppMinValue_EXC_ATTR,
      selectedRppMaxValue_EXC_ATTR,
      selectedRppSpfc_EXC_ATTR,

      selectedAttrColor_EXC_ATTR,
      selectedAttrFabric_EXC_ATTR,
      selectedAttrSize_EXC_ATTR,
      selectedAttributeTheme_EXC_ATTR,
      selectedProductBrand_EXC_ATTR,

      availableCustomerGroupOptions_EXC_ATTR,
      availableBusinessOptions_EXC_ATTR,
      availableAreaOptions_EXC_ATTR,
      availableShopDescOptions_EXC_ATTR,
      availableCategoryOptions_EXC_ATTR,
      availableMerchCatOptions_EXC_ATTR,
      collectionOptions,
            vendorOptions,
            articleStatusOptions,
            productBrandOptions,
            attributeColorOptions,
            attributeFabricOptions,
            attributeSizeOptions,
            attributeThemeOptions
    };

    const stateObject_INC_SKU = {
      selectedCustomerGroup_INC_SKU,
      selectedBusiness_INC_SKU,
      selectedArea_INC_SKU,
      selectedShopDesc_INC_SKU,
      selectedCategory_INC_SKU,
      selectedMerchCat_INC_SKU,

      selectedCharCollection_INC_SKU,
      selectedVendor_INC_SKU,
      selectedArticleStatus_INC_SKU,
      selectedRppMinValue_INC_SKU,
      selectedRppMaxValue_INC_SKU,
      selectedRppSpfc_INC_SKU,

      selectedAttrColor_INC_SKU,
      selectedAttrFabric_INC_SKU,
      selectedAttrSize_INC_SKU,
      selectedAttributeTheme_INC_SKU,
      selectedProductBrand_INC_SKU,

      selectedArticlesIds_INC_SKU,

      availableCustomerGroupOptions_INC_SKU,
      availableBusinessOptions_INC_SKU,
      availableAreaOptions_INC_SKU,
      availableShopDescOptions_INC_SKU,
      availableCategoryOptions_INC_SKU,
      availableMerchCatOptions_INC_SKU,
      collectionOptions,
            vendorOptions,
            articleStatusOptions,
            productBrandOptions,
            attributeColorOptions,
            attributeFabricOptions,
            attributeSizeOptions,
            attributeThemeOptions
    };

    const stateObject_EXC_SKU = {
      selectedCustomerGroup_EXC_SKU,
      selectedBusiness_EXC_SKU,
      selectedArea_EXC_SKU,
      selectedShopDesc_EXC_SKU,
      selectedCategory_EXC_SKU,
      selectedMerchCat_EXC_SKU,

      selectedCharCollection_EXC_SKU,
      selectedVendor_EXC_SKU,
      selectedArticleStatus_EXC_SKU,
      selectedRppMinValue_EXC_SKU,
      selectedRppMaxValue_EXC_SKU,
      selectedRppSpfc_EXC_SKU,

      selectedAttrColor_EXC_SKU,
      selectedAttrFabric_EXC_SKU,
      selectedAttrSize_EXC_SKU,
      selectedAttributeTheme_EXC_SKU,
      selectedProductBrand_EXC_SKU,

      selectedArticlesIds_EXC_SKU,

      availableCustomerGroupOptions_EXC_SKU,
      availableBusinessOptions_EXC_SKU,
      availableAreaOptions_EXC_SKU,
      availableShopDescOptions_EXC_SKU,
      availableCategoryOptions_EXC_SKU,
      availableMerchCatOptions_EXC_SKU,
      collectionOptions,
            vendorOptions,
            articleStatusOptions,
            productBrandOptions,
            attributeColorOptions,
            attributeFabricOptions,
            attributeSizeOptions,
            attributeThemeOptions
    };

    const isHierarchyBtnEnabled = _.get(
      this.props,
      "isHierarchyBtnEnabled",
      false
    );
    const hierarchyBtnClicked = _.get(this.props, "hierarchyBtnClicked", false);

    const isIncludeAttributeActive =
      hierarchyBtnClicked && isHierarchyBtnEnabled;
    const isIncludeSkuActive = hierarchyBtnClicked && isHierarchyBtnEnabled;
    let isExcludeSkuActive = false; // Activate exclude sku

    if (_.eq(pageType, "create")) {
      isExcludeSkuActive = hierarchyBtnClicked && isHierarchyBtnEnabled;
    } else if (_.eq(pageType, "edit")) {
      isExcludeSkuActive = isHierarchyBtnEnabled;
    }

    const tempCheckINC_ATTR_Obj = {
      selectedCustomerGroup_INC_ATTR,
      selectedBusiness_INC_ATTR,
      selectedArea_INC_ATTR,
      selectedShopDesc_INC_ATTR,
      selectedCategory_INC_ATTR,
      selectedMerchCat_INC_ATTR,

      selectedCharCollection_INC_ATTR,
      selectedVendor_INC_ATTR,
      selectedArticleStatus_INC_ATTR,
      selectedRppMinValue_INC_ATTR,
      selectedRppMaxValue_INC_ATTR,
      selectedRppSpfc_INC_ATTR,

      selectedAttrColor_INC_ATTR,
      selectedAttrFabric_INC_ATTR,
      selectedAttrSize_INC_ATTR,
      selectedAttributeTheme_INC_ATTR,
      selectedProductBrand_INC_ATTR,
    };
    const tempCheckINC_SKU_Obj = {
      selectedCustomerGroup_INC_SKU,
      selectedBusiness_INC_SKU,
      selectedArea_INC_SKU,
      selectedShopDesc_INC_SKU,
      selectedCategory_INC_SKU,
      selectedMerchCat_INC_SKU,

      selectedCharCollection_INC_SKU,
      selectedVendor_INC_SKU,
      selectedArticleStatus_INC_SKU,
      selectedRppMinValue_INC_SKU,
      selectedRppMaxValue_INC_SKU,

      selectedAttrColor_INC_SKU,
      selectedAttrFabric_INC_SKU,
      selectedAttrSize_INC_SKU,
      selectedAttributeTheme_INC_SKU,
      selectedProductBrand_INC_SKU,

      selectedArticlesIds_INC_SKU,
    };
    let isExcludeAttributeActive = false;

    _.mapKeys(tempCheckINC_ATTR_Obj, (value, key) => {
      if (_.isNumber(value)) {
        isExcludeAttributeActive = true && isIncludeAttributeActive;
        isExcludeSkuActive = true; // Activate Exclude SKU also

        return;
      }
      if (!_.isEmpty(value)) {
        isExcludeAttributeActive = true && isIncludeAttributeActive;
        isExcludeSkuActive = true; // Activate Exclude SKU also

        return;
      }
    });
    _.mapKeys(tempCheckINC_SKU_Obj, (value, key) => {
      if (_.isNumber(value)) {
        isExcludeSkuActive = true;
        return;
      }
      if (!_.isEmpty(value)) {
        isExcludeSkuActive = true;
        return;
      }
    });

    const isSaveBtnEnabled =
      hierarchyBtnClicked &&
      isHierarchyBtnEnabled &&
      (isExcludeAttributeActive || isExcludeSkuActive) &&
      !pageLoading;

    // Overwrite with Hierarchy Btn check
    isExcludeSkuActive = isExcludeSkuActive && isHierarchyBtnEnabled;

    const items = [
      {
        key: "1",
        label: "Include Attributes",
        children: (
          <IncludeAttributes
            collectionOptions={collectionOptions}
            articleStatusOptions={articleStatusOptions}
            handleSelectionChange_INC_ATTR={this.handleSelectionChange_INC_ATTR}
            stateObject={stateObject_INC_ATTR}
          />
        ),
        collapsible: isIncludeAttributeActive ? null : "disabled",
      },
      {
        key: "2",
        label: "Exclude Attributes",
        children: (
          <ExcludeAttributes
            collectionOptions={collectionOptions}
            articleStatusOptions={articleStatusOptions}
            handleSelectionChange_EXC_ATTR={this.handleSelectionChange_EXC_ATTR}
            stateObject={stateObject_EXC_ATTR}
          />
        ),
        collapsible: ( isExcludeAttributeActive || this.state.joann_cutover || ( this.state.selectedArticlesIds_INC_SKU && this.state.selectedArticlesIds_INC_SKU.length > 0 )  )  ? null : "disabled",
      },
      {
        key: "3",
        label: `Include SKU's`,
        children: (
          <IncludeSku
            collectionOptions={collectionOptions}
            articleStatusOptions={articleStatusOptions}
            handleSelectionChange_INC_SKU={this.handleSelectionChange_INC_SKU}
            stateObject={stateObject_INC_SKU}
            setSelectedSKUS_INC={this.setSelectedSKUS_INC}
            defaultSelectedRows={selectedSKU_INC}
            getRequestObjectForAttributes={this.getRequestObjectForAttributes}
          />
        ),
        collapsible: isIncludeSkuActive ? null : "disabled",
      },
      {
        key: "4",
        label: `Exclude SKU's`,
        children: (
          <ExcludeSku
            collectionOptions={collectionOptions}
            articleStatusOptions={articleStatusOptions}
            handleSelectionChange_EXC_SKU={this.handleSelectionChange_EXC_SKU}
            stateObject={stateObject_EXC_SKU}
            setSelectedSKUS_EXC={this.setSelectedSKUS_EXC}
            defaultSelectedRows={selectedSKU_EXC}
            getRequestObjectForAttributes={this.getRequestObjectForAttributes}
          />
        ),
        // collapsible: isExcludeSkuActive ? null : "disabled",
        collapsible: ( isExcludeAttributeActive ||  this.state.joann_cutover ) ? null : "disabled",
      },
    ];

    let validationErrors = [];
    let validationWarnings = [];
    let reqStatus = _.get(adgroupValidationObj, "reqStatus", false);

    let count_attr_inc = _.get(adgroupValidationObj, "count_attr_inc", 0);
    let count_attr_exc = _.get(adgroupValidationObj, "count_attr_exc", 0);
    let count_sku_inc = _.get(adgroupValidationObj, "count_sku_inc", 0);
    let count_sku_exc = _.get(adgroupValidationObj, "count_sku_exc", 0);
    let totalConsideredArticles = _.get(
      adgroupValidationObj,
      "totalConsideredArticles",
      0
    );

    let exclusion_sku_not_in_inclusion = _.get(
      adgroupValidationObj,
      "exclusion_sku_not_in_inclusion",
      []
    );
    let duplicate_sku_included_from_includesku = _.get(
      adgroupValidationObj,
      "duplicate_sku_included_from_includesku",
      []
    );
    let duplicate_sku_excluded_from_excludesku = _.get(
      adgroupValidationObj,
      "duplicate_sku_excluded_from_excludesku",
      []
    );

    let count_exclusion_sku_not_in_inclusion = _.size(
      exclusion_sku_not_in_inclusion
    );
    let count_duplicate_sku_included_from_includesku = _.size(
      duplicate_sku_included_from_includesku
    );
    let count_duplicate_sku_excluded_from_excludesku = _.size(
      duplicate_sku_excluded_from_excludesku
    );

    if (!_.isEmpty(adgroupValidationObj)) {
      if (_.eq(pageType, "create") && !reqStatus) {
        const msg = _.get(adgroupValidationObj, "msg", "");
        validationErrors.push(msg);
      }
      // if (totalConsideredArticles >=10000) {
      //   validationErrors.push('The Selection is having more articles than maxArticle limit(10000). Please change the selection criteria.');
      // }
      if (totalConsideredArticles <= 0 && _.isEmpty(validationErrors)) {
        validationErrors.push('The selection has no articles. Please change the selection criteria.');
      }
      if (count_sku_inc > 20) {
        validationErrors.push(
          "The Include SKU Selection criteria is having more than 20 articles. Please change the selection criteria."
        );
      }
      if (count_sku_exc > 20) {
        validationErrors.push(
          "The Exclude SKU Selection criteria is having more than 20 articles. Please change the selection criteria."
        );
      }
      
      if (count_exclusion_sku_not_in_inclusion > 0) {
        const jsx1 = () => {
          return (
            <>
              <Text
                style={{
                  fontSize: 12,
                }}
              >
                The Selection has Excluded articles which are not present in
                Included articles
              </Text>
              <Button
                type="link"
                size="small"
                onClick={() =>
                  this.handleShowSkuModal("exclusionsNotInInclusions")
                }
                style={{ fontSize: 12 }}
              >
                Excluded Articles not present in Included Articles(
                {count_exclusion_sku_not_in_inclusion})
              </Button>
            </>
          );
        };
        // validationWarnings.push(jsx1());
        validationErrors.push(jsx1());
      }

      if (count_duplicate_sku_included_from_includesku > 0) {
        const jsx2 = () => {
          return (
            <>
              <Text
                style={{
                  fontSize: 12,
                }}
              >
                The Selection in Include SKU Panel has articles which are also
                present in Include Attribute Panel Selection
              </Text>
              <Button
                type="link"
                size="small"
                onClick={() => this.handleShowSkuModal("duplicateInclusions")}
              >
                Duplicate Inclusions(
                {count_duplicate_sku_included_from_includesku})
              </Button>
            </>
          );
        };
        validationErrors.push(jsx2());
      }
      if (count_duplicate_sku_excluded_from_excludesku > 0) {
        const jsx3 = () => {
          return (
            <>
              <Text
                style={{
                  fontSize: 12,
                }}
              >
                The Selection in Exclude SKU Panel has articles which are also
                present in Exclude Attribute Panel Selection
              </Text>
              <Button
                type="link"
                size="small"
                onClick={() => this.handleShowSkuModal("duplicateExclusions")}
              >
                Duplicate Exclusions(
                {count_duplicate_sku_excluded_from_excludesku})
              </Button>
            </>
          );
        };
        validationErrors.push(jsx3());
      }
    }

    let resultTitle = "Adgroup Validation";
    if (adgroupSaved) {
      if (_.eq(pageType, "create")) {
        resultTitle = "Adgroup Created Successfully";
      } else if (_.eq(pageType, "edit")) {
        resultTitle = "Adgroup Updated Successfully";
      }
    }

    return (
      <PageLoader loader={this.state.showLoader}>
        <div className="alertDivs">
                 {this.state.isFailure ? (<AlertDanger
                    message={this.state.failureMessage}
                    show={this.state.isFailure}
                  />)
                 : null }
                 </div>
        <div className="alertDivs">
                 {this.state.isSuccess ? (<AlertSuccess
                    message={this.state.successMessage}
                    show={this.state.isSuccess}
                  />)
                 : null }
                 </div>
      <div style={{ marginTop: "15px" }}>
        <Collapse accordion items={items} />
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10px",
              }}
            >
              <div
                className="col-md-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="summaryPText invisible">text</p>
                <Button
                  type="primary"
                  onClick={this.handleSaveBtn}
                  disabled={!isSaveBtnEnabled || isIt()}
                >
                  Save
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          title=""
          open={modalOpen}
          onCancel={this.handleModalCancel}
          width={800}
          footer={[
            <Button
              key="back"
              onClick={this.handleModalCancel}
              disabled={pageLoading}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleModalOk}
              disabled={!_.isEmpty(validationErrors) || pageLoading}
              loading={pageLoading}
            >
              Save
            </Button>,
          ]}
        >
          <Result
            status={_.isEmpty(validationErrors) ? "success" : "info"}
            title={resultTitle}
            subTitle=""
          >
            <Row style={{ marginBottom: "10px" }}>
              <Col lg>
                <Tag
                  style={{ width: "100%", fontSize: 12 }}
                  color={_.isEmpty(validationErrors) ? "success" : "error"}
                >
                  Include Attributes Panel Article Count : {count_attr_inc}
                </Tag>
              </Col>
              <Col lg>
                <Tag
                  style={{ width: "100%", fontSize: 12 }}
                  color={_.isEmpty(validationErrors) ? "success" : "error"}
                >
                  Include SKU's Panel Article Count: {count_sku_inc}
                </Tag>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col lg>
                <Tag
                  style={{ width: "100%", fontSize: 12 }}
                  color={_.isEmpty(validationErrors) ? "success" : "error"}
                >
                  Exclude Attributes Panel Article Count : {count_attr_exc}
                </Tag>
              </Col>
              <Col lg>
                <Tag
                  style={{ width: "100%", fontSize: 12 }}
                  color={_.isEmpty(validationErrors) ? "success" : "error"}
                >
                  Exclude SKU's Panel Article Count: {count_sku_exc}
                </Tag>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p className="summaryPText invisible">text</p>
                    <Tag
                      style={{ width: "100%", fontSize: 12 }}
                      color={_.isEmpty(validationErrors) ? "success" : "error"}
                    >
                      Total Articles Considered: {totalConsideredArticles}
                    </Tag>
                  </div>
                </div>
              </Col>
            </Row>
            {!_.isEmpty(validationWarnings) && !adgroupSaved && (
              <div className="desc">
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 12,
                    }}
                  >
                    The Selection criteria has the following Warnings:
                  </Text>
                </Paragraph>
                {_.map(validationWarnings, (x, i) => {
                  return (
                    <Paragraph key={i} style={{ fontSize: 12 }}>
                      <WarningOutlined className="site-result-demo-error-icon" />{" "}
                      {x}
                    </Paragraph>
                  );
                })}
              </div>
            )}
            {!_.isEmpty(validationErrors) && !adgroupSaved && (
              <div className="desc">
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 12,
                    }}
                  >
                    The Selection criteria has the following error:
                  </Text>
                </Paragraph>
                {_.map(validationErrors, (x, i) => {
                  return (
                    <Paragraph key={i} style={{ fontSize: 12 }}>
                      <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                      {x}
                    </Paragraph>
                  );
                })}
              </div>
            )}
          </Result>
        </Modal>
        {showViewSkuModal ? (
          <SkuModalForAdgroupCreate
            showLoader={skuModalLoading}
            closeSkuModal={() => {
              this.setState({
                showViewSkuModal: false,
                skuModalLoading: false,
              });
            }}
            tableData={SKUTableData}
            footnote={[]}
            refreshRows={false}
            refreshRowsHandler={null}
            closeDetailPopup={() => {}}
            showDetailPopup={false}
            refSkuInfoResponse={null}
          />
        ) : null}
      </div>
    </PageLoader>);
  }
}

SkuSelectionMaster.propTypes = {
  productListFilters: PropTypes.func.isRequired,
  getValidCollections: PropTypes.func.isRequired,
  validateAdgroup: PropTypes.func.isRequired,
  togglePageLoading: PropTypes.func.isRequired,
  createAdgroupV2: PropTypes.func.isRequired,
  updateAdgroupV2: PropTypes.func.isRequired,
  getSkuListFromSkuIds: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    getAllHierarchiesData: state.adGroupData.getAllHierarchiesData,
    getAllAttributesData : state.adGroupData.getAllAttributesData
  };
};


export default connect(mapStateToProps, {
  productListFilters,
  getValidCollections,
  validateAdgroup,
  createAdgroupV2,
  updateAdgroupV2,
  getSkuListFromSkuIds,
  // getAllHierarchies,
  getMerchIdHierarchyMapping
})(withRouter(SkuSelectionMaster));
