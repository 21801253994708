import moment from "moment";
import numeral from "numeral";
import { getBrowserTimeZone, valueFormatterHandler , comparator } from "../../../utils/valueFormatters";

export default {

  defaultColDef: {
    resizable: true,
    // filter: true,
    sortable: true,
  },

  deactivatePromoColdef : [
    {
      headerName: "Adgroup",
      field: "adgroup_name",
      width: 180,
      pinned: "left",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
    },
    {
      headerName: "Adgroup Description",
      field: "adgroup_desc",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      width: 250,
      // valueGetter: emptyValueFormatterForString,
    },
    {
      headerName: "Merch Manager ID",
      field: "merch_id",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: emptyValueFormatterForString,
    },
    {
      headerName: "Start Date",
      field: "start_date",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        // if (params.data.start_date && params.data.rank === "1") {
        if (params.data.start_date) {
          return moment(params.data.start_date).format("MM-DD-YYYY");
        }
        return "-";
      },
      suppressSizeToFit: true,
      sortable: true,
      comparator: function (date1, date2) {
      return comparator(date1, date2, "MM-DD-YYYY");
    },
    },
    {
      headerName: "End Date",
      field: "end_date",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        // if (params.data.end_date && params.data.rank === "1") {
        if(params.data.end_date ) {
          return moment(params.data.end_date).format("MM-DD-YYYY");
        }
        return "-";
      },
      suppressSizeToFit: true,
      sortable: true,
      comparator: function (date1, date2) {
      return comparator(date1, date2, "MM-DD-YYYY");
    },
    },
    {
      headerName: "Event",
      field: "event_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: promoTypeFormatter,
    },
    {
      headerName: "Promosmart Offer",
      field: "offer",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: promoTypeFormatter,
    },
    {
      headerName: "Promosmart Offer Type",
      field: "offer_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: promoTypeFormatter,
    },
    {
      headerName: "Deactivated By",
      field: "deactivated_by",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: promoTypeFormatter,
    },
    {
      headerName: "Deactivated At",
      field: "deactivated_at",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueGetter: function (params) {
        if (params.data.deactivated_at) {
          return getBrowserTimeZone(params.data.deactivated_at);
        }
        return "-";
      },
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
      // valueGetter: promoTypeFormatter,
    },
  ]
}