import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { Card, Row, Col } from 'react-bootstrap';
import { InputNumber, Typography, Divider, Select, Button, Table, Input , Space } from 'antd';
import {
  getProductListV2
} from "../../../../../redux/actions/adgroup";
import { PlusOutlined } from '@ant-design/icons';


const { Title, Text } = Typography;
const { TextArea } = Input;
const columns = [
  {
    title: 'SKU',
    dataIndex: 'unique_id1',
    fixed: 'left',
    width: 100
  },
  {
    title: 'SKU Description',
    dataIndex: 'description',
    fixed: 'left',
    width: 150

  },
  {
    title: 'Retail Price($)',
    dataIndex: 'price',
    fixed: 'left',
    width: 100

  },
  {
    title: 'Cost($)',
    dataIndex: 'cost',
    fixed: 'left',
    width: 100
  },
  {
    title: 'Customer Group',
    dataIndex: 'customer_group',
    width: 100
  },
  {
    title: 'Business',
    dataIndex: 'business',
    width: 100
  },
  {
    title: 'Area',
    dataIndex: 'area',
    width: 100
  },
  {
    title: 'Shop',
    dataIndex: 'shop_desc',
    width: 100
  },
  {
    title: 'Category',
    dataIndex: 'category_desc',
    width: 100
  },
  {
    title: 'Merch Category',
    dataIndex: 'merch_cat',
    width: 100
  },
  {
    title: 'Char Collection',
    dataIndex: 'collection',
    width: 100
  },
  {
    title: 'Vendor Id',
    dataIndex: 'vendor_id',
    width: 150
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor_name',
    width: 150
  },
  {
    title: 'Article Status',
    dataIndex: 'article_status',
    width: 100
  },
  {
    title: 'In Store Date',
    dataIndex: 'in_store_date',
    width: 150
  },
  {
    title: 'Create Date',
    dataIndex: 'prod_creation_date',
    width: 150
  },
];

// const {
//   VENDOR_OPTIONS,
//   ATTRIBUTE_COLOR_OPTIONS,
//   ATTRIBUTE_FABRIC_OPTIONS,
//   ATTRIBUTE_SIZE_OPTIONS,
//   ATTRIBUTE_THEME_OPTIONS,
//   PRODUCT_BRAND_OPTIONS
// } = require('../utils/adgroup-constants');

const columnMapping = {
  "selectedCustomerGroup_EXC_SKU": "h6",
  "selectedBusiness_EXC_SKU": "h5",
  "selectedArea_EXC_SKU": "h4",
  "selectedShopDesc_EXC_SKU": "h3",
  "selectedCategory_EXC_SKU": "h2",
  "selectedMerchCat_EXC_SKU": "h1",

  "selectedCharCollection_EXC_SKU": "collection",
  "selectedVendor_EXC_SKU": "vendor_id",
  "selectedArticleStatus_EXC_SKU": "article_status",
  "selectedRppMinValue_EXC_SKU": "price_min",
  "selectedRppMaxValue_EXC_SKU": "price_max",
  "selectedRppSpfc_EXC_SKU": "Rpp_Spfc",
  "selectedAttrColor_EXC_SKU": "attribute_color",
  "selectedAttrFabric_EXC_SKU": "attribute_fabric",
  "selectedAttrSize_EXC_SKU": "attribute_size",
  "selectedAttributeTheme_EXC_SKU": "attribute_theme",
  "selectedProductBrand_EXC_SKU": "product_brand",
  "selectedArticlesIds_EXC_SKU": "unique_id1",

  "CharCollection": "collection",
  "Vendor_Ids": "vendor_id",
  "ArticleStatus": "article_status",
  "RppMin": "price_min",
  "RppMax": "price_max",
  "Rpp_Spfc": "Rpp_Spfc",
  "AttrColor": "attribute_color",
  "AttrFabric": "attribute_fabric",
  "AttrSize": "attribute_size",
  "AttrTheme": "attribute_theme",
  "ProductBrand": "product_brand",
  
}
class ExcludeSku extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      dataSource: [],
      tableLoading: false,
      selectedRowKeys: [],
      rppInputName: '',
      rppItemList: []
    };
  }
  
  async componentDidMount() {
    const defaultSelected = _.get(this.props, 'defaultSelectedRows', []);
    if(!_.isEmpty(defaultSelected)) {
      await this.fetchProducts()
      .then((response) => {
        if (!_.isEmpty(this.state.dataSource)) {
          const selectedRows = [];
          for(let data of this.state.dataSource) {
            // if(defaultSelected.includes(Number(data.unique_id1).toString())) {
            //   selectedRows.push(data.unique_id1);
            // }
            if(defaultSelected.includes(data.unique_id1.toString())) {
              selectedRows.push(data.unique_id1);
            }
          }
          this.setState({ selectedRowKeys:  selectedRows})
        }
      })
      .catch(() => {
      });
    }
    const { selectedRppSpfc_EXC_SKU } = this.props.stateObject;
    if (!_.isEmpty(selectedRppSpfc_EXC_SKU)) {
      this.setState({ rppItemList: selectedRppSpfc_EXC_SKU });
    }
  }

  marketingDisable() {
    let role = localStorage.getItem('role')
    if(['3'].indexOf(role)!== -1) {
      return true;
    }
    return false;
  }

  fetchProducts = () => {
    const { 
      selectedCustomerGroup_EXC_SKU,
      selectedBusiness_EXC_SKU,
      selectedArea_EXC_SKU,
      selectedShopDesc_EXC_SKU,
      selectedCategory_EXC_SKU,
      selectedMerchCat_EXC_SKU,

      selectedCharCollection_EXC_SKU,
      selectedVendor_EXC_SKU,
      selectedArticleStatus_EXC_SKU,
      selectedRppMinValue_EXC_SKU,
      selectedRppMaxValue_EXC_SKU,
      selectedRppSpfc_EXC_SKU,
      selectedAttrColor_EXC_SKU,
      selectedAttrFabric_EXC_SKU,
      selectedAttrSize_EXC_SKU,
      selectedAttributeTheme_EXC_SKU,
      selectedProductBrand_EXC_SKU,

      selectedArticlesIds_EXC_SKU,
      collectionOptions ,
      vendorOptions ,
      articleStatusOptions ,
      productBrandOptions ,
      attributeColorOptions ,
      attributeFabricOptions ,
      attributeSizeOptions ,
      attributeThemeOptions,
    } = this.props.stateObject;
    const reqObjectForAttributes = this.props.getRequestObjectForAttributes();

    const checkingObj = {
      selectedCustomerGroup_EXC_SKU,
      selectedBusiness_EXC_SKU,
      selectedArea_EXC_SKU,
      selectedShopDesc_EXC_SKU,
      selectedCategory_EXC_SKU,
      selectedMerchCat_EXC_SKU,

      selectedCharCollection_EXC_SKU,
      selectedVendor_EXC_SKU,
      selectedArticleStatus_EXC_SKU,
      selectedRppMinValue_EXC_SKU,
      selectedRppMaxValue_EXC_SKU,
      selectedRppSpfc_EXC_SKU,
      selectedAttrColor_EXC_SKU,
      selectedAttrFabric_EXC_SKU,
      selectedAttrSize_EXC_SKU,
      selectedAttributeTheme_EXC_SKU,
      selectedProductBrand_EXC_SKU,

      selectedArticlesIds_EXC_SKU,
      // ... reqObjectForAttributes.attr_exclusion
    };
    this.setState({ tableLoading: true }); // Set Loading

    const transformedReqObj = {};
    _.mapKeys(checkingObj, (value, key) => {
      const keyExists = _.get(columnMapping, key, false);
      if(keyExists && _.isNumber(value)) { // For Retail Price Point
        transformedReqObj[keyExists] = value;
        return;
      }
      if(keyExists && !_.isEmpty(value)) {
        transformedReqObj[keyExists] = value;
      }
    });

    if(selectedVendor_EXC_SKU && selectedVendor_EXC_SKU.length > 0){
      let vendor_exc_ids = this.props.stateObject.vendorOptions.filter(obj=> selectedVendor_EXC_SKU.indexOf(obj.value) !==-1).map(obj=> obj.vendor_id)
      transformedReqObj[`vendor_id`] = vendor_exc_ids
    }

    return this.props.getProductListV2(transformedReqObj)
    .then((response) => {
      const data = _.get(response, 'data', []);
      const finalData = _.map(data, (ob) => {
        return { ...ob, key: ob.unique_id1}
      });
      this.setState({ dataSource: finalData, tableLoading: false })
    })
    .catch(() => {
      this.setState({ dataSource: [], tableLoading: false })
    });
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    this.setState({ selectedRowKeys: selectedRowKeys });
    this.props.setSelectedSKUS_EXC(selectedRowKeys);
  }

  onRppInputChange = (event) => {
    this.setState({ rppInputName: event });
  };

  addItem = (e) => {
    e.preventDefault();
    const oldList = this.state.rppItemList;
    const oldItemName = this.state.rppInputName;
    if(!_.isNumber(oldItemName)) {
      return;
    }
    this.setState({ 
      rppItemList: [...oldList, oldItemName],
      rppInputName: ''
    });
  };
  
  render() {
    const {
      selectedCustomerGroup_EXC_SKU,
      selectedBusiness_EXC_SKU,
      selectedArea_EXC_SKU,
      selectedShopDesc_EXC_SKU,
      selectedCategory_EXC_SKU,
      selectedMerchCat_EXC_SKU,

      selectedCharCollection_EXC_SKU,
      selectedVendor_EXC_SKU,
      selectedArticleStatus_EXC_SKU,
      selectedRppMinValue_EXC_SKU,
      selectedRppMaxValue_EXC_SKU,
      selectedAttrColor_EXC_SKU,
      selectedAttrFabric_EXC_SKU,
      selectedAttrSize_EXC_SKU,
      selectedAttributeTheme_EXC_SKU,
      selectedProductBrand_EXC_SKU,

      selectedArticlesIds_EXC_SKU, // Articles Ids Input

      availableCustomerGroupOptions_EXC_SKU,
      availableBusinessOptions_EXC_SKU,
      availableAreaOptions_EXC_SKU,
      availableShopDescOptions_EXC_SKU,
      availableCategoryOptions_EXC_SKU,
      availableMerchCatOptions_EXC_SKU,
      collectionOptions ,
      vendorOptions ,
      articleStatusOptions ,
      productBrandOptions ,
      attributeColorOptions ,
      attributeFabricOptions ,
      attributeSizeOptions ,
      attributeThemeOptions,
      selectedRppSpfc_EXC_SKU
    } = this.props.stateObject;
    const { 
      // collectionOptions, articleStatusOptions,
       handleSelectionChange_EXC_SKU } = this.props;
    const { dataSource, tableLoading, selectedRowKeys } = this.state;

    const checkingObj = {
      selectedCustomerGroup_EXC_SKU,
      selectedBusiness_EXC_SKU,
      selectedArea_EXC_SKU,
      selectedShopDesc_EXC_SKU,
      selectedCategory_EXC_SKU,
      selectedMerchCat_EXC_SKU,

      selectedCharCollection_EXC_SKU,
      selectedVendor_EXC_SKU,
      selectedArticleStatus_EXC_SKU,
      selectedRppMinValue_EXC_SKU,
      selectedRppMaxValue_EXC_SKU,

      selectedAttrColor_EXC_SKU,
      selectedAttrFabric_EXC_SKU,
      selectedAttrSize_EXC_SKU,
      selectedAttributeTheme_EXC_SKU,
      selectedProductBrand_EXC_SKU,

      selectedArticlesIds_EXC_SKU
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
      // getCheckboxProps: (record) => ({
      //   disabled: record.name === 'Disabled User',
      //   // Column configuration not to be checked
      //   name: record.name,
      // }),
    };

    let isBtnDisabled = true;
    _.mapKeys(checkingObj, (value, key) => {
      if(value && _.isNumber(value)) { // For Retail Price Point
        isBtnDisabled = false;
        return;
      }
      if(value && !_.isEmpty(value)) {
        isBtnDisabled = false;
      }
      if(this.marketingDisable()) { //marketing user should have - view only
        isBtnDisabled = true;
      }
    });

    const { rppInputName, rppItemList } = this.state;

    return (
      <Card>
        <Card.Body style={{ padding: '0px 0px 0px 0px'}}>
          <Card.Body>
          {/* <Row>
              <Col>
                <Title level={4}>
                  <Text strong>Attributes</Text>
                </Title>
              </Col>
            </Row>
            <Row style={{ marginTop: '5px', marginBottom: '15px'}}>
              <Col lg>
                <p className="summaryPText">Collection</p>
                <Select
                  placeholder="Select Collection"
                  value={selectedCharCollection_EXC_SKU}
                  options={collectionOptions}
                  onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedCharCollection_EXC_SKU')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Vendor</p>
                <Select
                  placeholder="Select Vendor"
                  value={selectedVendor_EXC_SKU}
                  options={vendorOptions}
                  onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedVendor_EXC_SKU')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Article Status</p>
                <Select
                  placeholder="Select Article Status"
                  value={selectedArticleStatus_EXC_SKU}
                  options={articleStatusOptions}
                  onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedArticleStatus_EXC_SKU')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Product Brand</p>
                  <Select
                    placeholder="Select Product Brand"
                    value={selectedProductBrand_EXC_SKU}
                    options={productBrandOptions}
                    onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedProductBrand_EXC_SKU')}
                    style={{ width: '100%'}}
                    mode="multiple"
                    allowClear={false}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
              </Col>
              
            </Row>

            <Row style={{ marginTop: '15px', marginBottom: '15px'}}>
              <Col lg>
                <p className="summaryPText">Attribute Color</p>
                <Select
                  placeholder="Select Attribute Color"
                  value={selectedAttrColor_EXC_SKU}
                  options={attributeColorOptions}
                  onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedAttrColor_EXC_SKU')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Attribute Fabric</p>
                <Select
                  placeholder="Select Attribute Fabric"
                  value={selectedAttrFabric_EXC_SKU}
                  options={attributeFabricOptions}
                  onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedAttrFabric_EXC_SKU')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Attribute Size</p>
                <Select
                  placeholder="Select Attribute Size"
                  value={selectedAttrSize_EXC_SKU}
                  options={attributeSizeOptions}
                  onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedAttrSize_EXC_SKU')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Attribute Theme</p>
                <Select
                  placeholder="Select Attribute Theme"
                  value={selectedAttributeTheme_EXC_SKU}
                  options={attributeThemeOptions}
                  onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedAttributeTheme_EXC_SKU')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Col>
            </Row>
            <Row>
             <Col lg>
                <p className="summaryPText">Retail Price Point (Range)</p>
                <Text className="summaryPText">Min </Text>
                <InputNumber
                  placeholder="Min"
                  style={{ width: '35%'}}
                  value={selectedRppMinValue_EXC_SKU}
                  min={0}
                  max={100000000}
                  step={0.1}
                  addonBefore={`$`}
                  onChange={(v,y) => handleSelectionChange_EXC_SKU(v,'selectedRppMinValue_EXC_SKU')}
                  keyboard
                />
                <Text className="summaryPText" style={{ marginLeft: '10px'}}>Max </Text>
                <InputNumber
                  placeholder="Max"
                  style={{ width: '35%'}}
                  value={selectedRppMaxValue_EXC_SKU}
                  min={0}
                  max={100000000}
                  step={0.1}
                  addonBefore={`$`}
                  onChange={(v,y) => handleSelectionChange_EXC_SKU(v,'selectedRppMaxValue_EXC_SKU')}
                  keyboard
                />
              </Col> 
              <Col xs={12} sm={12} md={4} lg={4}>
                <p className="summaryPText">Retail Price Point(Specific)</p>
                <Select
                  style={{
                    width: 300,
                  }}
                  placeholder="Add Retail Price Point"
                  mode="multiple"
                  allowClear={false}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: '8px 0',
                        }}
                      />
                      <Space
                        style={{
                          padding: '0 8px 4px',
                        }}
                      >
                        <InputNumber
                          placeholder="Please enter retail price point"
                          value={rppInputName}
                          min={0}
                          max={100000000}
                          step={0.1}
                          addonBefore={`$`}
                          onChange={this.onRppInputChange}
                          keyboard
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={this.addItem}>
                          Add price
                        </Button>
                      </Space>
                    </>
                  )}
                  options={rppItemList.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedRppSpfc_EXC_SKU')}
                  value={selectedRppSpfc_EXC_SKU}
                />
              </Col>
              <Col lg/>
              <Col lg/>
            </Row>

            <Divider style={{ marginTop: '15px', marginBottom: '10px'}}/> */}

            <Row>
              <Col lg>
                <Row style={{ marginTop: '5px', marginBottom: '15px'}}>
                  <Col lg/>
                  <Col lg>
                    <p className="summaryPText">Article Ids</p>
                    <TextArea
                      placeholder="Please Enter Article Ids"
                      autoSize={{
                        minRows: 1,
                        maxRows: 10,
                      }}
                      maxLength={1000}
                      value={selectedArticlesIds_EXC_SKU}
                      onChange={(v) => handleSelectionChange_EXC_SKU(v.target.value,'selectedArticlesIds_EXC_SKU')}
                      disabled = {this.marketingDisable()}
                    />
                  </Col>
                  <Col lg/>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: '10px'}}>
              <Col>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px'}}>
                  <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <p className="summaryPText invisible">text</p>
                    <Button 
                      type="primary" 
                      disabled={isBtnDisabled}
                      onClick={this.fetchProducts}
                      loading={tableLoading}
                    >
                      Select Products
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: '10px'}}>
              <Col>
                <Table
                  // rowSelection={{
                  //   type: 'checkbox',
                  //   ...rowSelection,
                  // }}
                  {...(localStorage.getItem('role') == 3 ? {} : {rowSelection : {type: 'checkbox',...rowSelection}} )}
                  columns={columns}
                  dataSource={dataSource}
                  loading={tableLoading}
                  scroll={{
                    x: 1300,
                  }}
                />
              </Col>
            </Row>

          </Card.Body>
        </Card.Body>
      </Card>
    );

    // return (
    //   <Card>
    //     <Card.Body style={{ padding: '0px 0px 0px 0px'}}>
    //       <Card.Body>
    //         <Row>
    //           <Col>
    //             <Title level={4}>
    //               <Text strong>Hierarchy</Text>
    //             </Title>
    //           </Col>
    //         </Row>
    //         <Row style={{ marginTop: '5px', marginBottom: '15px'}}>
    //           <Col lg>
    //             <p className="summaryPText">Customer Group</p>
    //             <Select
    //               placeholder="Select Customer Group"
    //               value={selectedCustomerGroup_EXC_SKU}
    //               options={availableCustomerGroupOptions_EXC_SKU}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedCustomerGroup_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           <Col lg>
    //             <p className="summaryPText">Business</p>
    //             <Select
    //               placeholder="Select Business"
    //               value={selectedBusiness_EXC_SKU}
    //               options={availableBusinessOptions_EXC_SKU}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedBusiness_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           {/* <Col lg>
    //             <p className="summaryPText">Area</p>
    //             <Select
    //               placeholder="Select Area"
    //               value={selectedArea_EXC_SKU}
    //               options={availableAreaOptions_EXC_SKU}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedArea_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //             />
    //           </Col> */}
    //           <Col lg>
    //             <p className="summaryPText">Shop Description</p>
    //             <Select
    //               placeholder="Select Shop Description"
    //               value={selectedShopDesc_EXC_SKU}
    //               options={availableShopDescOptions_EXC_SKU}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedShopDesc_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           <Col lg>
    //             <p className="summaryPText">Category</p>
    //             <Select
    //               placeholder="Select Category"
    //               value={selectedCategory_EXC_SKU}
    //               options={availableCategoryOptions_EXC_SKU}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedCategory_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //         </Row>

    //         <Row style={{ marginTop: '5px', marginBottom: '0px'}}>
    //           <Col lg>
    //             <p className="summaryPText">Merchandise Category</p>
    //             <Select
    //               placeholder="Select Merchandise Category"
    //               value={selectedMerchCat_EXC_SKU}
    //               options={availableMerchCatOptions_EXC_SKU}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedMerchCat_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           <Col lg />
    //           <Col lg />
    //           <Col lg />
    //         </Row>

    //         <Divider style={{ marginTop: '15px', marginBottom: '10px'}}/>

    //         <Row>
    //           <Col>
    //             <Title level={4}>
    //               <Text strong>Attributes</Text>
    //             </Title>
    //           </Col>
    //         </Row>
    //         <Row style={{ marginTop: '5px', marginBottom: '15px'}}>
    //           <Col lg>
    //             <p className="summaryPText">Collection</p>
    //             <Select
    //               placeholder="Select Collection"
    //               value={selectedCharCollection_EXC_SKU}
    //               options={collectionOptions}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedCharCollection_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           <Col lg>
    //             <p className="summaryPText">Vendor</p>
    //             <Select
    //               placeholder="Select Vendor"
    //               value={selectedVendor_EXC_SKU}
    //               options={VENDOR_OPTIONS}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedVendor_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //                 // option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 // option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           <Col lg>
    //             <p className="summaryPText">Article Status</p>
    //             <Select
    //               placeholder="Select Article Status"
    //               value={selectedArticleStatus_EXC_SKU}
    //               options={articleStatusOptions}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedArticleStatus_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           <Col lg>
    //             <p className="summaryPText">Retail Price Point</p>
    //             <Text className="summaryPText">Min </Text>
    //             <InputNumber
    //               placeholder="Min"
    //               style={{ width: '35%'}}
    //               value={selectedRppMinValue_EXC_SKU}
    //               min={0}
    //               max={100000000}
    //               step={0.1}
    //               addonBefore={`$`}
    //               // formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //               // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
    //               onChange={(v,y) => handleSelectionChange_EXC_SKU(v,'selectedRppMinValue_EXC_SKU')}
    //               keyboard
    //             />
    //             <Text className="summaryPText" style={{ marginLeft: '10px'}}>Max </Text>
    //             <InputNumber
    //               placeholder="Max"
    //               style={{ width: '35%'}}
    //               value={selectedRppMaxValue_EXC_SKU}
    //               min={0}
    //               max={100000000}
    //               step={0.1}
    //               addonBefore={`$`}
    //               // formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //               // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
    //               onChange={(v,y) => handleSelectionChange_EXC_SKU(v,'selectedRppMaxValue_EXC_SKU')}
    //               keyboard
    //             />
    //           </Col>
    //         </Row>

    //         <Row style={{ marginTop: '15px', marginBottom: '15px'}}>
    //           <Col lg>
    //             <p className="summaryPText">Attribute Color</p>
    //             <Select
    //               placeholder="Select Attribute Color"
    //               value={selectedAttrColor_EXC_SKU}
    //               options={ATTRIBUTE_COLOR_OPTIONS}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedAttrColor_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           <Col lg>
    //             <p className="summaryPText">Attribute Fabric</p>
    //             <Select
    //               placeholder="Select Attribute Fabric"
    //               value={selectedAttrFabric_EXC_SKU}
    //               options={ATTRIBUTE_FABRIC_OPTIONS}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedAttrFabric_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           <Col lg>
    //             <p className="summaryPText">Attribute Size</p>
    //             <Select
    //               placeholder="Select Attribute Size"
    //               value={selectedAttrSize_EXC_SKU}
    //               options={ATTRIBUTE_SIZE_OPTIONS}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedAttrSize_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //           <Col lg>
    //             <p className="summaryPText">Attribute Theme</p>
    //             <Select
    //               placeholder="Select Attribute Theme"
    //               value={selectedAttributeTheme_EXC_SKU}
    //               options={ATTRIBUTE_THEME_OPTIONS}
    //               onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedAttributeTheme_EXC_SKU')}
    //               style={{ width: '100%'}}
    //               mode="multiple"
    //               allowClear={false}
    //               showSearch={true}
    //               filterOption={(input, option) =>
    //                 option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                 option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //               }
    //             />
    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col lg>
    //             <p className="summaryPText">Product Brand</p>
    //               <Select
    //                 placeholder="Select Product Brand"
    //                 value={selectedProductBrand_EXC_SKU}
    //                 options={PRODUCT_BRAND_OPTIONS}
    //                 onChange={(v) => handleSelectionChange_EXC_SKU(v,'selectedProductBrand_EXC_SKU')}
    //                 style={{ width: '100%'}}
    //                 mode="multiple"
    //                 allowClear={false}
    //                 showSearch={true}
    //                 filterOption={(input, option) =>
    //                   option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //                   option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //                 }
    //               />
    //           </Col>
    //           <Col lg/>
    //           <Col lg/>
    //           <Col lg/>
    //         </Row>

    //         <Divider style={{ marginTop: '15px', marginBottom: '10px'}}/>
            
    //         <Row style={{ marginTop: '10px'}}>
    //           <Col>
    //             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px'}}>
    //               <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    //                 <p className="summaryPText invisible">text</p>
    //                 <Button 
    //                   type="primary" 
    //                   disabled={isBtnDisabled}
    //                   onClick={this.fetchProducts}
    //                   loading={tableLoading}
    //                 >
    //                   Select Products
    //                 </Button>
    //               </div>
    //             </div>
    //           </Col>
    //         </Row>

    //         <Row style={{ marginTop: '10px'}}>
    //           <Col>
    //             <Table
    //               rowSelection={{
    //                 type: 'checkbox',
    //                 ...rowSelection,
    //               }}
    //               columns={columns}
    //               dataSource={dataSource}
    //               loading={tableLoading}
    //               scroll={{
    //                 x: 1300,
    //               }}
    //             />
    //           </Col>
    //         </Row>

    //       </Card.Body>
    //     </Card.Body>
    //   </Card>
    // );
  }
}

ExcludeSku.propTypes = {
  collectionOptions: PropTypes.array.isRequired,
  articleStatusOptions: PropTypes.array.isRequired,
  handleSelectionChange_EXC_SKU: PropTypes.func.isRequired,
  stateObject: PropTypes.object.isRequired,
  getProductListV2: PropTypes.func.isRequired,
  setSelectedSKUS_EXC: PropTypes.func.isRequired
};

export default connect(null, {
  getProductListV2
})(ExcludeSku);
