export default {
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true,
  },
  agparReportHeader: [
    {
      headerName: "Adgroup name",
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      pinned : "left"
    },
    {
      headerName: "Adgroup Desc",
      field: "adgroup_desc",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Buyer ID",
      field: "buyer",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
    },
  ],
  agdarReportHeader: [
    {
      headerName: "Themes",
      field: "theme",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 150
    },
    {
      headerName: "Event ID",
      field: "event_id",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 150
    },
    {
      headerName: "Event Desc",
      field: "event_desc",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 150
    },
    {
      headerName: "Adgroup name",
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      pinned : "left"  ,
      width : 150
    },
    {
      headerName: "Adgroup Desc",
      field: "adgroup_desc",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Article ID",
      field: "article_id",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 150
    },
    {
      headerName: "Article Desc",
      field: "article_desc",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Merchcat",
      field: "merchcat",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Store Type",
      field: "store_type",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 150
    },
    {
      headerName: "Status",
      field: "status",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 100
    },
    {
      headerName: "Price List",
      field: "price_list",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 150
    },
    {
      headerName: "Cost",
      field: "Cost",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 100
      // valueGetter: function (params) {
      //   if (params.value) {
      //     let valueVar = `$ ${params.value}`;
      //     return valueVar;
      //   }
      //   return "-";
      // }
    },
    {
      headerName: "Retail",
      field: "price",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 100
    },
    {
      headerName: "UAS",
      field: "uas",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 75
    },
    {
      headerName: "Offer Type",
      field: "offer_type",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width: 150
    },
    {
      headerName: "Offer",
      field: "offer",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 100 ,
      valueGetter: function (params) {
        if (params && params.data && params.data.offer) {
          let offerValue = params.data.offer;
          if(offerValue.includes('.')) {
            let twoOfferValues = offerValue.split(".")
            if(twoOfferValues[1].length == 1) {
              offerValue = offerValue + "0"
            }
          }
          // Add a space after "$" and before "OFF" if "OFF" follows a number 
          //Used Regex here -> if regex pattern matches it will add spaces between them , if offer format is correct it will not be affected
          offerValue = offerValue.replace(/\$(\d+)(OFF)/, "$ $1 $2");
          return offerValue;
        }
        return "-";
      },
    },
    {
      headerName: "GMM ID",
      field: "gmm",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 100
    },
    // {
    //   headerName: "GMM Name",
    //   field: "gmm_name",
    //   headerClass: "blue-header-bg",
    //   filter: "agTextColumnFilter",
    // },
    {
      headerName: "Buyer ID",
      field: "buyer",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 120
    },
    {
      headerName: "Buyer Name",
      field: "buyer_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width : 150
    }
  ]
};