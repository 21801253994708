import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "react-awesome-modal";
import Select from "react-select";

import _ from "lodash";
import moment from "moment";

import PageLoader from "../../../../utils/Loader";
import { getCampaignsData } from "../../../../redux/actions/eventMasterAction";
import { getEventList, copyPromos } from "../../../../redux/actions/calendarAction";
import ApprovalStatusRenderer from "./ApprovalStatusRenderer";
import MultiSelectAll from "../EventMaster/MultiSelectDropdown";
import ColumnDef from "../../../tables/colDefs/CopyPromoColDef";
import DataTable from "../../../tables";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import { alertTimer } from "../../../../utils/alertTimer";
import { isIt } from "../../../../utils/getRoleName";

export class CopyOfferModal extends Component {

  state = {
    showLoader: false,
    selectedCampaign: [],
    selectedEvents: [],
    eventTypeOptions: [],
    promoDetailsData: {},
    errorEventPromos: [],
    promoEventErrorsData: {},
    disableCopy: false,
    successMessage: '',
    isSuccess: false,
    failureMessage: '',
    isFailed: false
  }

  async componentDidMount() {
    await this.props.getCampaignsData();

    let tableData = {
      header: ColumnDef.promoDetailsColdef,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.props.pipelineIntermediateData
    }

    this.setState({
      promoDetailsData: _.cloneDeep(tableData)
    })
  }

  promoTypeFormatter = value => {
    let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
    if (value) {
      return promoTypeSet ? promoTypeSet[value] : value;
    }
  };

  handleCampignChange = async (campaignValue) => {
    if (campaignValue) {
      this.setState({
        selectedCampaign: campaignValue,
        selectedEvents: [],
        eventTypeOptions: []
      })
    }
  }

  getEventsList = async () => {
    let selectedCampaignIds = this.state.selectedCampaign.filter(campaign => campaign.value != "all")
    const reqObj = {
      campaignId: selectedCampaignIds.map(campaigns => campaigns.value)
    }

    if (this.state.eventTypeOptions && this.state.eventTypeOptions.length == 0) {
      const res = await this.props.getEventList(reqObj);
      if (res.status) {
        let eventTypeOptions = [];
        res.data.forEach(eventOptions => {
          eventTypeOptions.push({
            value: eventOptions.e_id,
            label: eventOptions.ty_event,
          });
        });
        if(eventTypeOptions.length > 0 ) {
          eventTypeOptions.unshift({
            label: "Select all",
            value: "all"
          })
        }
        this.setState({
          eventTypeOptions: eventTypeOptions // _.uniqBy(eventTypeOptions, "value")
        });
      }
    }
  }

  handleEventschange = eventVal => {
    this.setState({
      selectedEvents: eventVal,
      disableCopy: false
    })
  }

  copyOffers = async () => {
    let selectedEventIds = this.state.selectedEvents.filter(campaign => campaign.value != "all")
    const reqObj = {
      offers: this.props.pipelineIntermediateData,
      destinationEvents: selectedEventIds.map(events => events.value)
    };

    this.setState({
      promoEventErrorsData: {},
      showLoader :  true
    })

    const result = await this.props.copyPromos(reqObj);

    let foundPromoApprovalRes = _.filter(result.data.data, { inPromoApproval: true });
    if(!(foundPromoApprovalRes && foundPromoApprovalRes)){
      foundPromoApprovalRes = _.filter(result.data, { inPromoApproval: true });
    }
    let duplicateArppovalRecord = _.filter(result.data.data, { status: false });

    console.log(result.message)


    if(foundPromoApprovalRes && foundPromoApprovalRes.length > 0 && foundPromoApprovalRes[0].inPromoApproval) {
      this.setState({
        isSuccess: true,
        successMessage: "Promo offer/s has been submitted for approval",
        showLoader: false
      })

      setTimeout(() => {
        this.setState({
          isSuccess: false,
          successMessage: ''
        })
        if(!(duplicateArppovalRecord && duplicateArppovalRecord.length > 0)){
          this.props.handleCloseCopyModal();
          this.props.updatePipelineData([], "intermediate", "");
        }
      },alertTimer);
    }


    if(result.status == false && result.message) {
      this.setState({
        isFailed: true,
        // isSuccess : true , 
        failureMessage: `${result.message}`,
        disableCopy: true
      })

      setTimeout(() => {
        this.setState({
          isFailed : false ,
          failureMessage : ''
        });
        this.props.handleCloseCopyModal();
        this.props.updatePipelineData([], "intermediate", "");
      }, alertTimer);

    }

    else if ( (result.data.errors && result.data.errors.length > 0) || (duplicateArppovalRecord && duplicateArppovalRecord.length > 0) ) {  
      let existingPromoError;

      if(result.data.errors && result.data.errors.length > 0) {
        existingPromoError = result.data.errors
        if(duplicateArppovalRecord && duplicateArppovalRecord.length > 0) {
            duplicateArppovalRecord = duplicateArppovalRecord.concat(existingPromoError)
        }
    }
    else if(duplicateArppovalRecord && duplicateArppovalRecord.length > 0) {
        if(result.data.errors && result.data.errors.length > 0) {
            duplicateArppovalRecord = duplicateArppovalRecord.concat(result.data.errors)
        }
    }
      // let tableData = {
      //   header: ColumnDef.promoEventErrorColdef,
      //   defaultColDef: ColumnDef.defaultColDef,
      //   data: result.data.errors
      // }

      let errorTableData = duplicateArppovalRecord.map(error => {
        return {
          adgroup_name: error.data.adgroupName,
          event_detail: error.data.ty_event,
          offer_type : error.data.offer_type,
          offer_value : error.data.offer_value,
          validationErrors : error.data.validationErrors ? error.data.validationErrors : '',
          status : error.status,
        };
    });
    let tableData = {
      header: ColumnDef.promoEventErrorColdef,
      defaultColDef: ColumnDef.defaultColDef,
      data : errorTableData
    }

      this.setState({
        disableCopy: true,
        showLoader :false,
        promoEventErrorsData: _.cloneDeep(tableData),
      });
    } else {
      this.setState({
        isSuccess: true,
        successMessage: `Promos copied successfully`,
        disableCopy: true
      })


      setTimeout(() => {
        this.setState({
          isSuccess: false,
          isFailed : false ,
          successMessage : '' , 
          failureMessage : ''
        });
        this.props.handleCloseCopyModal();
        this.props.updatePipelineData([], "intermediate", "");
      }, alertTimer);
    }
  }

  render() {
    return (
      <div className="adgrp-choice-modal">
        <Modal
          visible={true}
          width="90%"
          height={"86%"}
          effect="fadeInDown"
          position="relative"
          display="flex"
          flex-direction="column"
          onClickAway={() => this.props.handleCloseCopyModal()}
        >
          <PageLoader loader={this.state.showLoader || this.props.loader}>
            <div className="alertDivs">
              {this.state.isSuccess &&
                <AlertSuccess
                  message={this.state.successMessage}
                  show={this.state.isSuccess}
                />
              }
              {this.state.isFailed &&
                <AlertDanger
                  message={this.state.failureMessage}
                  show={this.state.isFailed}
                />
              }
            </div>
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.props.handleCloseCopyModal()}
                />
              </span>
            </div>
            <h4 className="promoHeading mb-4">
              Copy Offers
            </h4>
            
              <div className="row" style={{ marginLeft: "12px", height: "30px" }}>
                <div className="col-md-4">
                  <span className="promoNameText row">
                    Source Campaign Name:{" "}
                    <p className="promonameResult">
                      {/* {this.props.promotionName} */}
                      {moment(this.props.campaignStartDate).format("MM/DD/YYYY")} - {moment(this.props.campaignEndDate).format("MM/DD/YYYY")}
                    </p>
                  </span>
                </div>
                <div className="col-md-3">
                  <span
                    className="promoNameText row"
                    style={{ paddingLeft: "2%" }}
                  >
                    Start Date:
                    <p className="promonameResult">
                      {this.props.campaignStartDate}
                    </p>
                  </span>
                </div>
                <div className="col-md-3">
                  <span
                    className="promoNameText row"
                    style={{ paddingLeft: "2%" }}
                  >
                    End Date:
                    <p className="promonameResult">
                      {this.props.campaignEndDate}
                    </p>
                  </span>
                </div>
              </div>
           
            <div className="copyOfferContainer">
              <div className="copyDropdown">
                <p className="dropdownLabel">Campaign Name</p>
                <MultiSelectAll
                  placeholder="Campaign Name"
                  options={[
                    {
                      label: "Select all",
                      value: "all"
                    },
                    ...this.props.campaignOptions]}
                  value={this.state.selectedCampaign}
                  onchange={this.handleCampignChange}
                  onMenuClose={this.getEventsList}
                />
              </div>
              <div className="copyDropdown">
                <p className="dropdownLabel">Event</p>
                <MultiSelectAll
                  placeholder="Event"
                  options={this.state.eventTypeOptions}
                  value={this.state.selectedEvents}
                  onchange={this.handleEventschange}
                />
              </div>
            </div>
            {
              this.state.promoDetailsData.data &&
              <div className="copyOffertableContainer">
                <DataTable
                  parentId="myGridForCopyPromoDetails"
                  tableData={this.state.promoDetailsData}
                  parentStyle={{
                    height: this.state.promoEventErrorsData.data ? "20vh" : "40vh",
                    width: "100%",
                    position: "relative",
                  }}
                  parentClass="ag-theme-balham"
                />
              <div className="text-center mt-2 copybtn">
              <button
                className="btn btn-outline-primary promo3Buttons"
                disabled={!(this.state.selectedCampaign.length > 0 && this.state.selectedEvents.length > 0) || this.state.disableCopy || isIt()}
                onClick={() => {
                  this.copyOffers();
                }}
              >
                Copy
              </button>
              </div>
              </div>
            }
            {
              this.state.promoEventErrorsData.data &&
              <>
                <p className="errorMessage" style={{marginTop:"-1%"}}>Following offers are not copied</p>
                <div className="copyOffertableContainer">
                  <DataTable
                    parentId="myGridForCopyPromoErrorDetails"
                    tableData={this.state.promoEventErrorsData}
                    parentStyle={{
                      height: "20vh",
                      width: "100%",
                      position: "relative",
                    }}
                    frameworkComponents={{
                      approvalStatusRenderer: ApprovalStatusRenderer
                  }}
                    autoSizeCol={true}
                  />
                </div>
              </>
            }
            
          </PageLoader>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    campaignOptions: state.eventMaster.campaignOptions,
    loader: state.calendar.loader,
  };
};

export default connect(
  mapStateToProps,
  {
    getCampaignsData,
    getEventList,
    copyPromos
  }
)(CopyOfferModal);


